var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Informações")]),_c('v-divider'),_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-text',[_c('div',{staticClass:"my-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-identifier")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("ID:")]),_vm._v(" "+_vm._s(_vm.dados.iddepartamento)+" ")],1),_c('div',{staticClass:"my-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-outline")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Departamento:")]),_vm._v(" "+_vm._s(_vm.dados.departamento)+" ")],1),_c('div',{staticClass:"my-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Total de usuários:")]),_vm._v(" "+_vm._s(_vm.dados.qtdusuarios)+" ")],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',[_c('Table',{attrs:{"pgLimitGet":5,"backendDirectory":"usuario/listar","busca":{
                        iddepartamento: parseInt(_vm.iddepartamento),
                        busca: _vm.usuarios.busca || null
                    },"filter":true,"headers":{
                        erpidusuario: {nome: 'ID CISS', tipo: 'texto'},
                        usuario: {nome: 'Usuário', tipo: 'texto'},
                        nome: {nome: 'Nome', tipo: 'texto'},
                        status: {nome: 'Status', tipo: 'boolean', true: 'Ativo', false: 'Inativo'},
                    }}},[_c('v-col',[_vm._v("Usuários")]),_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.carregando,"label":"Buscar","outlined":"","dense":""},model:{value:(_vm.usuarios.busca),callback:function ($$v) {_vm.$set(_vm.usuarios, "busca", $$v)},expression:"usuarios.busca"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }