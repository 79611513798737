var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Table',{attrs:{"pgLimitGet":_vm.pgLimit,"backendDirectory":"ti/cronjob/db2","busca":{
					taskid: _vm.busca.taskid || null,
                },"filter":true,"keydown":_vm.keydown,"headers":{
                    taskid: {nome: 'Task ID', tipo: 'texto'},
                    cronjob: {nome: 'Cronjob', tipo: 'texto'},
                    mensagem: {nome: 'Mensagem', tipo: 'texto'},
                    status: {nome: 'Status', tipo: 'texto'},
                    sqlcode: {nome: 'SQL Code', tipo: 'texto'},
                    sqlstate: {nome: 'SQL State', tipo: 'texto'},
                    rc: {nome: 'RC', tipo: 'texto'},
                    dtinicio: {nome: 'Data Inicial', tipo: 'texto'},
                    dtfim: {nome: 'Data Final', tipo: 'texto'},
                    duracao: {nome: 'Duração execução', tipo: 'texto', class: 'text-truncate'},
                }}},[_c('v-col',{staticClass:"pr-1 d-none d-md-block"},[_vm._v("Logs DB2 Comissão")]),_c('v-col',{staticClass:"px-1"},[_c('v-select',{attrs:{"label":"Task ID","outlined":"","clearable":"","dense":"","items":_vm.cronjobs,"item-text":"cronjob","item-value":"taskid"},model:{value:(_vm.busca.taskid),callback:function ($$v) {_vm.$set(_vm.busca, "taskid", $$v)},expression:"busca.taskid"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }