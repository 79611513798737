<template>
  <v-row>

    <DialogDashboards :dialog="dialog" />

    <!-- SOMENTE FILTROS-->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
                <InputDatePicker :disabled="carregando" v-model="busca.dtinicio" label="Data inicial" :outlined="true"
                  :dense="true" />
              </v-col>
              <v-col class="d-none d-md-block px-1">
                <InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final" :outlined="true"
                  :dense="true" />
              </v-col>

              <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
                <v-sheet class="text-center" height="100%">
                  <v-row class="pa-3">
                    <v-col cols="6" class="px-2">
                      <InputDatePicker :disabled="carregando" v-model="busca.dtinicio" label="Data inicial"
                        :outlined="true" :dense="true" />
                    </v-col>
                    <v-col cols="6" class="px-2">
                      <InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final" :outlined="true"
                        :dense="true" />
                    </v-col>
                    <v-col cols="6">
                      <v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text class="mt-n3" color="primary" @click="listar(), sheet = !sheet">Filtrar</v-btn>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-bottom-sheet>
              <v-col cols="12" md="4" class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
                <v-autocomplete class="d-inline-flex d-sm-none" :items="filiaisFiltro" clearable item-text="filial"
                  item-value="idfilial" label="Filial" outlined dense :disabled="carregando" v-model="busca.idfilial"
                  append-outer-icon="mdi-menu" @click:append-outer="sheet = !sheet" hide-details
                  required></v-autocomplete>
                <v-autocomplete class="d-none d-sm-block" :items="filiaisFiltro" clearable item-text="filial"
                  item-value="idfilial" label="Filial" outlined dense :disabled="carregando" v-model="busca.idfilial"
                  hide-details required></v-autocomplete>
                <v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0" fab x-small
                  @click="listar()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 my-0 py-0">
          <small v-if="autoRefresh && !carregando" class="text--secondary text-caption">Próxima atualização em:
            {{ tempoRefresh }} segundos</small>
          <v-spacer />
          <span class="d-flex mb-n3">
            <v-switch :loading="carregando" v-model="autoRefresh" label="Atualização automática" color="primary"
              class="my-0 py-0"></v-switch>
          </span>
        </v-card-actions>
      </v-card>
    </v-col>

    <!-- CARDS-->
    <v-col cols="12" md="12">
      <v-row class="pa-2">
        <v-col class="pa-1">
          <CardDashboard :loading="carregando" :title="`Meta de Faturamento Mês`">
            <template v-if="!carregando">{{ dadosDRE.metaFaturamento | formataDinheiro }}</template>
            <template v-else>0.00</template>
            <MenuInfo texto="Meta de venda do apoio a decisão" />
          </CardDashboard>
        </v-col>
        <v-col class="pa-1">
          <CardDashboard :loading="carregando" title="Faturamento">
            <template v-if="!carregando">{{ dadosDRE.valorFaturamento | formataDinheiro }}</template>
            <template v-else>{{ 0 | formataDinheiro }}</template>
            <MenuInfo texto="Faturamento de acordo com DRE" />
          </CardDashboard>
        </v-col>
        <v-col class="pa-1">
          <CardDashboard :loading="carregando" title="Meta Atingida (%)">
            <template v-if="!carregando">{{ ((dadosDRE.valorFaturamento / dadosDRE.metaFaturamento) * 100 ||
              0).toFixed(2)
              }} %</template>
            <template v-else>0.00 %</template>
            <MenuInfo texto="% de atingimento da meta sobre o faturamento (DRE)" />
          </CardDashboard>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="12">
      <v-row class="pa-2">
        <v-col class="pa-1">
          <v-card class="v-card-conteudo">
            <v-card-title class="v-card-titulo">
              Faturamento
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-tooltip bottom color="primary" open-delay="100" content-class='custom-tooltip'>
                <template v-slot:activator="{ on, attrs }">
                  <v-row v-on="on" v-bind="attrs" class="d-flex justify-center align-center">
                    <v-skeleton-loader elevation="2" v-if="carregando" class="ma-auto" width="100%" height="156px"
                      type="image, image"></v-skeleton-loader>
                    <GraficoPonteiro v-else :key="JSON.stringify(dadosDRE.lista[0])"
                      :dataSeries="((dadosDRE.valorFaturamento / dadosDRE.metaFaturamento) * 100 || 0).toFixed(2)"
                      :height="'310px'" :colors="corPonteiroFaturamento()" />
                  </v-row>
                </template>
                <span>Percentual de atingimento da meta de Faturamento da DRE, definida pela Diretoria.</span>
              </v-tooltip>
            </v-card-text>
            <v-divider />
            <p class="p-texto-rodape">META MÊS: {{ dadosDRE.metaFaturamento || 0 | formataDinheiro }}</p>
          </v-card>
        </v-col>

        <v-col class="pa-1">
          <v-card class="v-card-conteudo">
            <v-card-title class="v-card-titulo">
              Devolução
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-tooltip bottom color="primary" open-delay="100" content-class='custom-tooltip'>
                <template v-slot:activator="{ on, attrs }">
                  <v-row v-on="on" v-bind="attrs" class="d-flex justify-center align-center">
                    <v-skeleton-loader elevation="2" v-if="carregando" class="ma-auto" width="100%" height="156px"
                      type="image, image"></v-skeleton-loader>
                    <GraficoPonteiroDRE v-else :maxValue="dadosDRE.metaDevolucao"
                      :key="JSON.stringify(dadosDRE.lista[10])" :dataSeries="dadosDRE.percDevolucao || 0"
                      :height="'310px'" :colors="corPonteiroDevolucao()" />
                  </v-row>
                </template>
                <span>Percentual de atingimento da meta de Devolução da DRE, definida pela Diretoria.</span>
              </v-tooltip>
            </v-card-text>
            <v-divider />
            <p class="p-texto-rodape">META MÊS: {{ dadosDRE.metaDevolucao || 0 }}%</p>
          </v-card>
        </v-col>

        <v-col class="pa-1">
          <v-card class="v-card-conteudo">
            <v-card-title class="v-card-titulo">
              Lucro Op. Bruto
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-tooltip bottom color="primary" open-delay="100" content-class='custom-tooltip'>
                <template v-slot:activator="{ on, attrs }">
                  <v-row v-on="on" v-bind="attrs" class="d-flex justify-center align-center">
                    <v-skeleton-loader elevation="2" v-if="carregando" class="ma-auto" width="100%" height="156px"
                      type="image, image"></v-skeleton-loader>
                    <GraficoPonteiroDRE v-else :maxValue="dadosDRE.metaLucro" :key="JSON.stringify(dadosDRE.lista[2])"
                      :dataSeries="dadosDRE.percLucro || 0" :height="'310px'" :colors="corPonteiroLucro()" />
                  </v-row>
                </template>
                <span>Percentual de atingimento da meta de Lucro Operacional Bruto da DRE, definida pela
                  Diretoria.</span>
              </v-tooltip>
            </v-card-text>
            <v-divider />
            <p class="p-texto-rodape">META MÊS: {{ dadosDRE.metaLucro || 0 }}%</p>
          </v-card>
        </v-col>

      </v-row>

    </v-col>

    <!-- GRAFICOS -->
    <template v-if="despesasDRE">
      <v-col cols="12">
        <v-card>
          <v-progress-linear indeterminate absolute :active="carregando" />
          <v-card-title>
            <span class="text-truncate">Demonstrativo de Despesas - {{ formataMes(this.mesSelecionado) }}</span>
            <v-spacer />
          </v-card-title>
          <v-card-text v-if="carregando">
            <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>
            <GraficoLineHorizontal :dataSeries="[
              { data: [despesasDRE.metaPessoal, despesasDRE.metaMarketing, despesasDRE.metaCombustivel, despesasDRE.metaFrota, despesasDRE.metaAgua, despesasDRE.metaEnergia, despesasDRE.metaConsumo] },
              { data: [despesasDRE.percPessoal, despesasDRE.percMarketing, despesasDRE.percCombustivel, despesasDRE.percFrota, despesasDRE.percAgua, despesasDRE.percEnergia, despesasDRE.percConsumo] }
            ]" :categories="['Pessoal', 'Marketing', 'Combustível', 'Frota', 'Água', 'Energia', 'Consumo',]" :maxValues="[14, 2, 2, 5, 0.8, 0.8, 1]" :colors="['#002D56', '#951015']" 
            :seriesLabels="['Limite Máximo Despesa', 'Total Gasto com Despesa']" :height="500" />

          </v-card-text>
        </v-card>
      </v-col>

    </template>


    <template v-if="dadosDRE">
      <v-col cols="6" v-if="dadosDRE.lista.length > 1">
        <v-card>
          <v-progress-linear indeterminate absolute :active="carregando" />
          <v-card-title>
            <span class="text-truncate">Comparativo Meta X Faturamento (R$) - Mensal - {{
              formataMes(this.mesSelecionado) }}</span>
            <v-spacer />
          </v-card-title>

          <v-card-text v-if="carregando">
            <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>

            <GraficoBarraDRE :dataSeries="[dadosDRE.metaFaturamento, dadosDRE.valorFaturamento]" :categories="[
              ['Meta'],
              ['Faturamento'],
            ]" :colors="['#002D56', '#951015', '#F2B90F', '#50A2E0']" :columnWidth="'45%'" :money="true" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="dadosDRE.lista.length > 1">
        <v-card>
          <v-progress-linear indeterminate absolute :active="carregando" />
          <v-card-title>
            <span class="text-truncate">Comparativo Despesas X Margem (%) - Mensal - {{ formataMes(this.mesSelecionado)
              }}</span>
            <v-spacer />
          </v-card-title>

          <v-card-text v-if="carregando">
            <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>

            <GraficoBarraDRE :dataSeries="[dadosDRE.percDespesas,
            dadosDRE.percLucro.toFixed(2)]" :categories="[
              ['Despesas'],
              ['Margem'],
            ]" :colors="['#F2B90F', '#50A2E0']" :columnWidth="'45%'" :money="false" :porcentagem="true" />
          </v-card-text>
        </v-card>
      </v-col>
    </template>

  </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import MenuInfo from "../Widgets/MenuInfo";
import DialogDashboards from "../Widgets/DialogDashboards";
import GraficoBarraDRE from "@/Components/Widgets/GraficoBarraDRE.vue";
import mixinFilial from "@/util/mixinFilial";
import GraficoPonteiro from "../Widgets/GraficoPonteiro.vue";
import GraficoPonteiroDRE from "../Widgets/GraficoPonteiroDRE.vue";
import GraficoLineHorizontal from "../Widgets/GraficoLineHorizontal.vue";

export default {
  name: "DashboardVendas",
  mixins: [mixinFilial],
  components: {
    GraficoPonteiro,
    GraficoPonteiroDRE,
    InputDatePicker,
    CardDashboard,
    MenuInfo,
    DialogDashboards,
    GraficoBarraDRE,
    GraficoLineHorizontal
  },
  data: () => ({
    tempoRefresh: 60,
    autoRefresh: false,
    Categorias: ['Venda DRE', 'Meta', 'Despesas'],
    corPadraoBarra: '#002D56',
    corBarraAcimaMeta: '#951015',
    corPadraoMeta: '#951015',
    corMetaBatida: '#002D56',
    mesSelecionado: "",
    sheet: false,
    dialog: { status: false, dados: [], header: {} },
    carregando: false,
    carregando2: false,
    filiais: [],
    regionais: [],
    dashboard: {},
    dadosDRE: {
      lista: [],
      despesas: [],
      percDevolucao: 0,
      valorDevolucao: 0,
      metaDevolucao: 0,
      percLucro: 0,
      valorLucro: 0,
      metaLucro: 0,
      valorFaturamento: 0,
      metaFaturamento: 0,
      percFaturamento: 0,
      valorDespesas: 0,
      percDespesas: 0,
      metaDespesas: 0,
    },
    despesasDRE: {
      nomePessoal: '',
      metaPessoal: 0,
      percPessoal: 0,
      valorPessoal: 0,
      nomeMarketing: '',
      metaMarketing: 0,
      percMarketing: 0,
      valorMarketing: 0,
      nomeConsumo: '',
      metaConsumo: 0,
      percConsumo: 0,
      valorConsumo: 0,
      nomeFrota: '',
      metaFrota: 0,
      percFrota: 0,
      valorFrota: 0,
      nomeCombustivel: '',
      metaCombustivel: 0,
      percCombustivel: 0,
      valorCombustivel: 0,
      nomeAgua: '',
      metaAgua: 0,
      percAgua: 0,
      valorAgua: 0,
      nomeEnergia: '',
      metaEnergia: 0,
      percEnergia: 0,
      valorEnergia: 0,
    },
    dia: `${new Date().toLocaleDateString("pt-BR")}`,
    busca: {
      dtinicio: `${new Date().getFullYear()}-${(new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getMonth() +
        1 <
        10
        ? "0"
        : "") +
        (new Date().getMonth() + 1)
        }-01`,
      dtfim: `${new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getFullYear()}-${(new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getMonth() +
        1 <
        10
        ? "0"
        : "") +
      (new Date().getMonth() + 1)
        }-${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate()}`,
      idfiliais: "",
      idfilial: "",
    },
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.map((v) => {
          const filial = {};
          filial.idfilial = v.idfilial;
          filial.filial = `${v.idfilial} - ${v.filial}`;
          return filial;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    async listar() {
      try {
        this.carregando = true;

        await this.listarMetaDRE();

        // Captura datas para verificar se esta no mes corrente
        const mesData = new Date(this.busca.dtfim).getMonth() + 1;
        const mesCorrente = new Date().getMonth() + 1;

        if (!isNaN(mesData)) { // Verifica se a data é válida
          if (mesData == mesCorrente) {
            await this.listarMetaLucro(); // Executa listarMetaLucro se estiver no mes atual
          }
        } else {
          console.error('A data é inválida:', this.busca.dtinicio);
        }
      } catch (error) {
        console.error('Ocorreu um erro ao listar:', error);
      } finally {
        this.carregando = false;
      }
    },
    listarMetaDRE() {
      this.capturaMes();
      this.formataMes(this.mesSelecionado);
      this.dadosDRE = {
        lista: [],
        despesas: [],
        percDevolucao: 0,
        valorDevolucao: 0,
        metaDevolucao: 0,
        percLucro: 0,
        valorLucro: 0,
        metaLucro: 0,
        valorFaturamento: 0,
        metaFaturamento: 0,
        percFaturamento: 0,
      };
      this.despesasDRE = {
        nomePessoal: '',
        metaPessoal: 0,
        percPessoal: 0,
        valorPessoal: 0,
        nomeMarketing: '',
        metaMarketing: 0,
        percMarketing: 0,
        valorMarketing: 0,
        nomeConsumo: '',
        metaConsumo: 0,
        percConsumo: 0,
        valorConsumo: 0,
        nomeFrota: '',
        metaFrota: 0,
        percFrota: 0,
        valorFrota: 0,
        nomeCombustivel: '',
        metaCombustivel: 0,
        percCombustivel: 0,
        valorCombustivel: 0,
        nomeAgua: '',
        metaAgua: 0,
        percAgua: 0,
        valorAgua: 0,
        nomeEnergia: '',
        metaEnergia: 0,
        percEnergia: 0,
        valorEnergia: 0,
      }
      this.carregando = true;
      return axios
        .post(`${this.backendUrl}filial/meta/dre`, {
          dtinicio: this.busca.dtinicio,
          dtfim: this.busca.dtfim,
          idfilial: String(this.busca.idfilial) || null,
        })
        .then((res) => {
          this.dadosDRE.lista = res.data == null ? [] : res.data.map((v) => {
            if (v.nomedre == 'Devoluções de Vendas') {
              this.dadosDRE.percDevolucao = v.percdre;
              this.dadosDRE.valorDevolucao = v.valordre;
              this.dadosDRE.metaDevolucao = v.meta
            } else if (v.nomedre == 'Lucro Operacional Bruto (3-4)') {
              this.dadosDRE.percLucro = v.percdre;
              this.dadosDRE.valorLucro = v.valordre;
              this.dadosDRE.metaLucro = v.meta
            } else if (v.nomedre == 'Receita Operacional Bruta') {
              this.dadosDRE.valorFaturamento = v.valordre;
              this.dadosDRE.metaFaturamento = v.metafaturamento;
              this.dadosDRE.percFaturamento = v.percdre;
            } else if (v.nomedre == 'Despesas Operacionais') {
              this.dadosDRE.valorDespesas = v.valordre;
              this.dadosDRE.metaDespesas = v.metafaturamento;
              this.dadosDRE.percDespesas = v.percdre;
            }

            // Separando despesas
            if (v.nomedre == 'Despesas Com Pessoal') {
              this.despesasDRE.nomePessoal = v.nomedre;
              this.despesasDRE.metaPessoal = v.meta;
              this.despesasDRE.percPessoal = v.percdre;
              this.despesasDRE.valorPessoal = v.valordre;
            } else if (v.nomedre == 'Despesas Com Consumo') {
              this.despesasDRE.nomeConsumo = v.nomedre;
              this.despesasDRE.metaConsumo = v.meta;
              this.despesasDRE.percConsumo = v.percdre;
              this.despesasDRE.valorConsumo = v.valordre;
            } else if (v.nomedre == 'Marketing') {
              this.despesasDRE.nomeMarketing = v.nomedre;
              this.despesasDRE.metaMarketing = v.meta;
              this.despesasDRE.percMarketing = v.percdre;
              this.despesasDRE.valorMarketing = v.valordre;
            } else if (v.nomedre == 'Despesas Com Frota') {
              this.despesasDRE.nomeFrota = v.nomedre;
              this.despesasDRE.metaFrota = v.meta;
              this.despesasDRE.percFrota = v.percdre;
              this.despesasDRE.valorFrota = v.valordre;
            } else if (v.nomedre == 'Despesas Com Combustivel e lubrificantes') {
              this.despesasDRE.nomeCombustivel = v.nomedre;
              this.despesasDRE.metaCombustivel = v.meta;
              this.despesasDRE.percCombustivel = v.percdre;
              this.despesasDRE.valorCombustivel = v.valordre;
            } else if (v.nomedre == 'Água e Saneamento') {
              this.despesasDRE.nomeAgua = v.nomedre;
              this.despesasDRE.metaAgua = v.meta;
              this.despesasDRE.percAgua = v.percdre;
              this.despesasDRE.valorAgua = v.valordre;
            } else if (v.nomedre == 'Energia Elétrica') {
              this.despesasDRE.nomeEnergia = v.nomedre;
              this.despesasDRE.metaEnergia = v.meta;
              this.despesasDRE.percEnergia = v.percdre;
              this.despesasDRE.valorEnergia = v.valordre;
            }
            return {
              ...v
            }
          });
        })
        .catch(() => {
        });
    },
    listarMetaLucro() {
      return axios
        .post(`${this.backendUrl}filial/meta/dre/current`, {
          dtinicio: this.busca.dtinicio,
          dtfim: this.busca.dtfim,
          idfilial: String(this.busca.idfilial),
        })
        .then((res) => {
          this.dadosDRE.percLucro = res.data[0].percdre;
          this.dadosDRE.valorLucro = res.data[0].valordre;
        })
        .catch(() => {
        });
    },
    timerRefresh() {
      if (this.tempoRefresh < 0) {
        this.tempoRefresh = 60;
      }
      setTimeout(() => {
        this.tempoRefresh--;
        this.timerRefresh();
      }, 1000);
    },
    abrirDialog(dados, header, title) {
      this.dialog.status = true;
      this.dialog.dados = dados;
      this.dialog.header = header;
      this.dialog.title = title;
    },
    capturaMes() {
      this.mesSelecionado = new Date(this.busca.dtfim).getMonth() + 1;
    },
    formataMes(mes) {
      switch (mes) {
        case 1:
          return 'Janeiro';
        case 2:
          return 'Fevereiro';
        case 3:
          return 'Março';
        case 4:
          return 'Abril';
        case 5:
          return 'Maio';
        case 6:
          return 'Junho';
        case 7:
          return 'Julho';
        case 8:
          return 'Agosto';
        case 9:
          return 'Setembro';
        case 10:
          return 'Outubro';
        case 11:
          return 'Novembro';
        case 12:
          return 'Dezembro';
      }
    },
    corPonteiroFaturamento() {
      let cor1 = ['#951015'];
      let cor2 = ['#F2B90F'];
      let cor3 = ['#50A2E0'];
      if (this.dadosDRE.valorFaturamento > this.dadosDRE.metaFaturamento) {
        return cor3;
      } else if (this.dadosDRE.valorFaturamento >= this.dadosDRE.metaFaturamento / 2) {
        return cor2;
      } else {
        return cor1;
      }
    },
    corPonteiroDevolucao() {
      let cor1 = ['#951015'];
      let cor2 = ['#F2B90F'];
      let cor3 = ['#50A2E0'];
      if (this.dadosDRE.percDevolucao > this.dadosDRE.metaDevolucao) {
        return cor1;
      } else if (this.dadosDRE.percDevolucao >= this.dadosDRE.metaDevolucao / 2) {
        return cor2;
      } else {
        return cor3;
      }
    },
    corPonteiroLucro() {
      let cor1 = ['#951015'];
      let cor2 = ['#F2B90F'];
      let cor3 = ['#50A2E0'];
      if (this.dadosDRE.percLucro > this.dadosDRE.metaLucro) {
        return cor3;
      } else if (this.dadosDRE.percLucro >= this.dadosDRE.metaLucro / 2) {
        return cor2;
      } else {
        return cor1;
      }
    },
    async init() {
      if (this.usuario.idfilial == 39) {
        this.busca.idfilial = 1;
      } else if (this.usuario.idfilial == 40) {
        this.busca.idfilial = 1;
      } else {
        this.busca.idfilial = this.usuario.idfilial;
      }
      await this.listar();
      await this.capturaMes();

      //this.timerRefresh();
    },
  },
  watch: {
    "busca.dtfim": function () {
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtinicio = this.busca.dtfim;
      }
    },
    "busca.dtinicio": function () {
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtfim = this.busca.dtinicio;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.v-card-conteudo {
  margin: 0px !important;
}

.v-card-titulo {
  justify-content: center !important;
  text-transform: uppercase !important;
  padding: 8px 16px;
  font-weight: bold;
  color: #13456B;
}

.p-texto-rodape {
  text-align: center;
  padding: 8px 16px;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #951015;
}
</style>