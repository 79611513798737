import { render, staticRenderFns } from "./InicioContainer.vue?vue&type=template&id=0b17cd0e&scoped=true"
import script from "./InicioContainer.vue?vue&type=script&lang=js"
export * from "./InicioContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b17cd0e",
  null
  
)

export default component.exports