<template>
  <VueApexCharts
      type="radialBar"
      :height="height"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraficoPonteiroDRE",
  components: { VueApexCharts },
  props: {
    dataSeries: { type: Number, required: true }, // Valor recebido
    maxValue: { type: Number, required: true },  // Limite máximo configurado
    height: { type: String, default: "310px" },
    colors: {
      type: Array,
      default: () => ["#50A2E0", "#FFA500", "#FF0000"], // Azul, Laranja, Vermelho
    },
  },
  data() {
    return {
      series: [0], // Série inicial
      chartOptions: {
        colors: this.colors, // Cor inicial
        chart: {
          type: "radialBar",
          offsetY: -30,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 15,
              size: '45%',     // Tamanho oco no centro
              background: 'transparent', // Fundo transparente
            },
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              },
            },
            dataLabels: {
              name: {
                show: false,
                fontSize: '20px'
              },
              value: {
                offsetY: -5,
                show: true,
                fontWeight: 'bold',
                fontSize: '26px',
                color: '#13456B',
                formatter: () => `${this.dataSeries.toFixed(2)}%`,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.1,
            inverseColors: false,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0,50, 100],
            colorStops: []
          },
        },
      },
    };
  },
  methods: {
    initChart() {
      const value = this.dataSeries || 0;

      // Calcula a série proporcional em relação ao limite máximo
      const proportionalValue = (value / this.maxValue) * 100;
      this.series = [proportionalValue];

      this.chartOptions.colors = this.colors;

    },
  },
  watch: {
    // Atualiza o gráfico sempre que os valores mudarem
    dataSeries: "initChart",
    maxValue: "initChart",
    colors: {
      handler: "initChart",
      deep: true, // Garantir que mudanças no array sejam detectadas
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>
