var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[_c('v-text-field',{attrs:{"clearable":"","autofocus":"","label":"Busca de Produto","placeholder":"Informe o código ou descrição do produto","outlined":"","dense":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.listar(0)}},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}}),_c('v-btn',{staticClass:"primary ml-3",attrs:{"height":"38px"},on:{"click":function($event){return _vm.listar(0)}}},[_vm._v(" Buscar "),_c('v-icon',{staticClass:"pl-2",attrs:{"size":"20"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-card',{staticClass:"primary mt-3",attrs:{"width":"100%"}},[(_vm.buscado)?_c('v-card-title',{staticClass:"white--text"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_vm._v("Resultados para \""+_vm._s(_vm.buscado)+"\"")]),(!_vm.carregando)?_c('v-col',{staticClass:"pa-0 mb-n3 mt-n1",staticStyle:{"font-size":"small"}},[_vm._v(_vm._s(_vm.produtos.total)+" itens encontrados.")]):_vm._e()],1):_c('v-card-title',{staticClass:"white--text"},[_vm._v(" Consulta de Produtos ")]),_c('v-card-text',[(_vm.carregandoSkeleton)?_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"headers":[
                { text: 'Código', value: 'idsubproduto', align:'left', class: 'pl-5', sortable: false},
                { text: 'Descrição', value: 'descricao', align:'left',  sortable: false},
                { text: 'Quantidade Estoque CD', value: 'qtdestoque', align:'center', sortable: false},
                { text: 'Quantidade Reservada', value: 'qtdestoquereservada', align:'center', sortable: false},
                { text: 'Quantidade Disponível', value: 'qtdestoquedisponivel', align:'center', sortable: false},
            ],"items":_vm.produtos.lista,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticClass:"text-left pl-5"},[_vm._v(" "+_vm._s(item.idsubproduto)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.descricao)+" ")]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticStyle:{"width":"90px","justify-content":"center"},attrs:{"small":""}},[_c('strong',[_vm._v(_vm._s(item.qtdestoque))])])],1),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticStyle:{"width":"90px","justify-content":"center"},attrs:{"small":""}},[_c('strong',[_vm._v(_vm._s(item.qtdestoquereservada))])])],1),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticStyle:{"width":"90px","justify-content":"center"},attrs:{"small":""}},[_c('strong',[_vm._v(_vm._s(item.qtdestoque - item.qtdestoquereservada))])])],1)])]}}])}),(_vm.produtos.total>1)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('Paginacao',{attrs:{"total":_vm.produtos.total,"pgLimitInt":_vm.pgLimit,"carregando":_vm.carregando,"pg":_vm.pgSelecionada},on:{"atualizar":_vm.listar}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }