let title = ' | Gestor - Comercial Ivaiporã'

const cd = {
    path: '/gestaoprecarga',
    props: true,
    component: () => import('@/Components/GestaoPreCarga/PreCargaContainer.vue'),
    children: [
        {
            path: '',
            name: 'ListagemPreCarga',
            component: () => import('@/Components/GestaoPreCarga/ListagemPreCarga.vue'),
            meta: {
                title: 'Pre-carga' + title,
                permission: 'cd_menu',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Gestão de Pre-cargas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/gestao-precarga',
                    }
                ]
            }
        },
    ]
}

export default cd