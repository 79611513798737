var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Table',{attrs:{"pgLimitGet":_vm.pgLimit,"backendDirectory":"entrega/listar","busca":{
            busca: _vm.busca.busca || null,
            status: _vm.busca.status || null,
            dtini: _vm.busca.dtinicio || null,
            dtfim: _vm.busca.dtfim || null,
            idfilial: _vm.busca.idfilial || null,
        },"filter":true,"keydown":_vm.keydown,"headers":{
            idsolicitacao: {nome: 'ID', tipo: _vm.permissao('entrega_get') ? 'link' : 'texto', link: '/entrega/get/'},
            idcliente: {nome: 'Cód. Cliente', tipo: 'texto'},
            cliente: {nome: 'Cliente', tipo: 'texto'},
            dtsolicitacao: {nome: 'Data solicitação', tipo: 'texto'},
            filial: {nome: 'Filial', tipo: 'texto'},
            status: {nome: 'Status', tipo: 'boolean', true: 'Entregue', false: 'Pendente', class: 'text-center'},
            cancelado: {nome: 'Cancelado', tipo: 'boolean', true: 'Sim', false: 'Não', class: 'text-center'},
        }}},[_c('v-col',{staticClass:"d-none d-md-block pr-1"},[_c('InputDatePicker',{attrs:{"outlined":true,"dense":true,"label":"Data inicial"},model:{value:(_vm.busca.dtinicio),callback:function ($$v) {_vm.$set(_vm.busca, "dtinicio", $$v)},expression:"busca.dtinicio"}})],1),_c('v-col',{staticClass:"d-none d-md-block px-1"},[_c('InputDatePicker',{attrs:{"outlined":true,"dense":true,"label":"Data final"},model:{value:(_vm.busca.dtfim),callback:function ($$v) {_vm.$set(_vm.busca, "dtfim", $$v)},expression:"busca.dtfim"}})],1),(_vm.filtroEmpresa)?_c('v-col',{staticClass:"d-none d-md-block px-1"},[_c('v-select',{attrs:{"clearable":"","items":_vm.filiaisFiltro,"item-text":"filial","item-value":"idfilial","label":"Filial","outlined":"","dense":""},model:{value:(_vm.busca.idfilial),callback:function ($$v) {_vm.$set(_vm.busca, "idfilial", $$v)},expression:"busca.idfilial"}})],1):_vm._e(),_c('v-col',{staticClass:"d-none d-md-block px-1"},[_c('v-select',{attrs:{"clearable":"","items":[
                        {value: 'F', text : 'Pendente'},
                        {value: 'T', text : 'Entregas gerada'},
                        {value: 'C', text : 'Cancelado'},
                    ],"label":"Status","outlined":"","dense":""},model:{value:(_vm.busca.status),callback:function ($$v) {_vm.$set(_vm.busca, "status", $$v)},expression:"busca.status"}})],1),_c('v-bottom-sheet',{staticClass:"d-inline-flex d-md-none",model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"100%"}},[_c('v-row',{staticClass:"pa-3"},[(_vm.filtroEmpresa)?_c('v-col',{staticClass:"pr-3 pr-md-0 mb-n8",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"clearable":"","items":_vm.filiaisFiltro,"item-text":"filial","item-value":"idfilial","label":"Filial","outlined":"","dense":""},model:{value:(_vm.busca.idfilial),callback:function ($$v) {_vm.$set(_vm.busca, "idfilial", $$v)},expression:"busca.idfilial"}})],1):_vm._e(),_c('v-col',{staticClass:"pr-3 pr-md-0 mb-n8",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"clearable":"","items":[
                                    {value: 'F', text : 'Pendente'},
                                    {value: 'T', text : 'Entregas gerada'},
                                    {value: 'C', text : 'Cancelado'},
                                ],"label":"Status","outlined":"","dense":""},model:{value:(_vm.busca.status),callback:function ($$v) {_vm.$set(_vm.busca, "status", $$v)},expression:"busca.status"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('InputDatePicker',{attrs:{"label":"Data inicial","outlined":true,"dense":true},model:{value:(_vm.busca.dtinicio),callback:function ($$v) {_vm.$set(_vm.busca, "dtinicio", $$v)},expression:"busca.dtinicio"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('InputDatePicker',{attrs:{"label":"Data final","outlined":true,"dense":true},model:{value:(_vm.busca.dtfim),callback:function ($$v) {_vm.$set(_vm.busca, "dtfim", $$v)},expression:"busca.dtfim"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mt-n3",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v("Cancelar")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mt-n3",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.keydown = !_vm.keydown, _vm.sheet = !_vm.sheet}}},[_vm._v("Filtrar")])],1)],1)],1)],1),_c('v-col',{staticClass:"pl-1"},[_c('v-text-field',{staticClass:"d-flex d-md-none",attrs:{"label":"Buscar","outlined":"","dense":"","append-outer-icon":"mdi-menu"},on:{"click:append-outer":function($event){_vm.sheet = !_vm.sheet},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.keydown = !_vm.keydown}},model:{value:(_vm.busca.busca),callback:function ($$v) {_vm.$set(_vm.busca, "busca", $$v)},expression:"busca.busca"}}),_c('v-text-field',{staticClass:"d-none d-md-block",attrs:{"label":"Buscar","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.keydown = !_vm.keydown}},model:{value:(_vm.busca.busca),callback:function ($$v) {_vm.$set(_vm.busca, "busca", $$v)},expression:"busca.busca"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }