var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Table',{attrs:{"pgLimitGet":1000,"backendDirectory":"filial/listar","busca":{
            busca: _vm.busca.busca || null
        },"filter":true,"keydown":_vm.keydown,"headers":{
            idfilial: {nome: 'ID', tipo: _vm.permissao('filial_get') ? 'link' : 'texto', link: '/filial/get/'},
            filial: {nome: 'Filial', tipo: 'texto'},
            endereco: {nome: 'Endereço', tipo: 'texto'},
            cep: {nome: 'CEP', tipo: 'cep', class: 'text-truncate'},
            contato: {nome: 'Contato', tipo: 'contato', class: 'text-truncate'},
        }}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.keydown = !_vm.keydown}},model:{value:(_vm.busca.busca),callback:function ($$v) {_vm.$set(_vm.busca, "busca", $$v)},expression:"busca.busca"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }