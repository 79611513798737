import { render, staticRenderFns } from "./ConcretoProduto.vue?vue&type=template&id=16044182&scoped=true"
import script from "./ConcretoProduto.vue?vue&type=script&lang=js"
export * from "./ConcretoProduto.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16044182",
  null
  
)

export default component.exports