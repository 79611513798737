var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Table',{attrs:{"pgLimitGet":_vm.pgLimit,"backendDirectory":"cliente/listar","backendDirectoryUpdate":"cliente/atualizar/situacao","busca":{
            busca: _vm.busca.busca || null
        },"parametro":{
      busca: _vm.busca.busca || null
    },"filter":true,"keydown":_vm.keydown,"headers":{
            idcliente: {nome: 'ID', tipo: _vm.permissao('cliente_get') ? 'link' : 'texto', link: '/cliente/get/', class: 'width'},
            nome: {nome: 'Nome', tipo: 'texto'},
            endereco: {nome: 'Endereço', tipo: 'objeto', campos: ['endereco','numero','cidade'], divisor: ', '},
            situacao: {nome: 'Medalha da Cobrança', tipo:'select', permissao: _vm.permissao('atualiza_situacao_cliente'), situacoes: [{idsituacao: 0, situacao: 'Sem Medalha'},{idsituacao: 1, situacao: 'Ouro'},{idsituacao: 2, situacao: 'Prata'},{idsituacao: 3, situacao: 'Bronze'}] },
        }}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.keydown = !_vm.keydown}},model:{value:(_vm.busca.busca),callback:function ($$v) {_vm.$set(_vm.busca, "busca", $$v)},expression:"busca.busca"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }