import { render, staticRenderFns } from "./ChamadoNotificacoes.vue?vue&type=template&id=3af7c640&scoped=true"
import script from "./ChamadoNotificacoes.vue?vue&type=script&lang=js"
export * from "./ChamadoNotificacoes.vue?vue&type=script&lang=js"
import style0 from "./ChamadoNotificacoes.vue?vue&type=style&index=0&id=3af7c640&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af7c640",
  null
  
)

export default component.exports