var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Table',{attrs:{"pgLimitGet":100,"backendDirectory":"compra/produtocusto/atualizar/fila","refresh":true,"headers":{
            idempresa: {nome: 'Cód. Filial', tipo: 'texto'},
            empresa: {nome: 'Filial', tipo: 'texto'},
            idSubproduto: {nome: 'Cód. Produto', tipo: 'texto'},
            produto: {nome: 'Produto', tipo: 'texto'},
            margem: {nome: 'Margem', tipo: 'porcentagem'},
            dtmovimento: {nome: 'Data e Hora', tipo: 'texto'},
        }}},[_c('v-col',[_vm._v("Produtos em fila de atualização")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }