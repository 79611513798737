import { render, staticRenderFns } from "./LiberaClienteCimento.vue?vue&type=template&id=dd0bf6a2&scoped=true"
import script from "./LiberaClienteCimento.vue?vue&type=script&lang=js"
export * from "./LiberaClienteCimento.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd0bf6a2",
  null
  
)

export default component.exports