<template>
  <v-row>

    <DialogDashboards :dialog="dialog"/>
    <v-dialog v-model="dialog2.status" max-width="575" persistent>
      <v-card class="pa-5">
        <v-card-title class="text-h4">
          <span class="mx-auto">{{ dialog2.title }}</span>
        </v-card-title>
        <div class="text-center py-3">
          <v-icon size="100" :color="dialog2.iconColor">{{ dialog2.icon }}</v-icon>
        </div>
        <v-card-text class="text-h6 text-center">{{ dialog2.msg }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" elevation="0" class="px-10" @click="dialog2.status = false, get()">OK</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Verificação de titulos a serem baixados-->
    <v-dialog v-model="dialogVerifica" scrollable>
      <v-card class="primary">
        <v-card-subtitle class="white--text text-center pt-6 pb-4 pl-4 pr-3 ">

          <span class="text-h6">TÍTULOS A SEREM BAIXADOS</span>
        </v-card-subtitle>
        <v-divider />
        <v-card-text class="px-3 pb-0">
          <v-simple-table fixed-header height="50vh" widht="80vh" class="table">
            <template v-slot:default>
              <thead class="header">
              <tr>
                <th>Cliente</th>
                <th>Título</th>
                <th class="pr-0">Digito Titulo</th>
                <th class="pr-0">Saldo Título</th>
                <th class="pr-0">Juros Mora</th>
                <th class="pr-0">Valor Atualizado</th>
                <th class="pr-0">Tipo</th>
              </tr>
              </thead>
              <tbody class="body text-capitalize" v-if="selecionados.length > 0">
              <tr v-for="(d, i) in selecionados" :key="i">
                <td class="pr-0" nowrap>{{ d.cliente }}</td>
                <td class="pr-0" nowrap>{{ d.titulo }}</td>
                <td class="pr-0" nowrap>{{ d.digitotitulo }}</td>
                <td class="pr-0">{{ d.saldotitulo | formataDinheiro }}</td>
                <td class="pr-0">{{ d.jurosmora | formataDinheiro }}</td>
                <td class="pr-0">{{ d.valoratualizado | formataDinheiro }}</td>
                <td class="pr-0">{{ d.tipo }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="text-center" colspan="7">Nenhum título selecionado.</td>
              </tr>
              </tbody>
              <tfoot class="foot text-capitalize">
              <tr class="cursor-normal">
                <td nowrap class="bold-text">Total a ser baixado:</td>
                <td nowrap></td>
                <td nowrap></td>
                <td nowrap class="bold-text">{{ selecionados.map(v =>
                    v.saldotitulo).reduce((a, v) => a + v, 0) |
                    formataDinheiro }}</td>
                <td nowrap class="bold-text">{{ selecionados.map(v =>
                    v.jurosmora).reduce((a, v) => a + v, 0) |
                    formataDinheiro }}</td>
                <td nowrap class="bold-text">{{ selecionados.map(v =>
                    v.valoratualizado).reduce((a, v) => a + v, 0) |
                    formataDinheiro }}</td>
                <td nowrap class="bold-text"></td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="pa-1 pb-3 pr-3">
          <v-spacer />
          <v-btn :loading="carregando" class="px-5 white--text" color="red darken-3" elevation="0"
                 @click="dialogVerifica = false">
            Fechar
            <v-icon class="pl-2 pb-1">mdi-close</v-icon>
          </v-btn>
          <v-btn :loading="carregando" class="px-5 white--text" color="green darken-1" elevation="0"
                 @click="baixarTitulos()">
            Salvar
            <v-icon class="pl-2 pb-1">mdi-content-save</v-icon>
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-col cols="12">
      <!-- FILTROS -->
      <v-card class="my-2">
        <v-card-title>
          <v-row no-gutters>
            <v-col class="pr-2">
              <v-select :items="datas" item-text="name" item-value="value" label="Período" outlined dense
                        v-model="busca.data" hide-details></v-select>
            </v-col>
            <v-col class="pr-2">
              <v-select :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial"
                        outlined dense v-model="busca.idfiliais" hide-details multiple
                        class="single-line-selection">
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.filial }}</span>
                  <span v-if="index === 1" class="grey--text text-caption">
										(+{{ busca.idfiliais.length - 1 }})
									</span>
                </template>

                <template #prepend-item>
                  <v-list-item class="pr-0" dense>
                    <v-list-item-action>
                      <v-checkbox v-model="selectAllChecked"
                                  @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content @click="toggleSelectAll('LABEL')">
                      <!-- Use uma tag label para vincular ao checkbox -->
                      <label class="text-subtitle-2">Todas</label>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col cols="auto" class="pl-2">
              <v-btn :disabled="carregando" class="mt-1" color="primary" elevation="0" fab rounded x-small
                     @click="listar(0)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
      </v-card>

      <v-card class="primary my-4" dark>

        <v-card-subtitle v-if="dados.totalcredu > 0" class="white--text font-weight-black text-center">
          <v-icon class="mr-2" size="30">mdi-cash</v-icon>
          <span>SALDO TOTAL DO CREDU A BAIXAR: {{ dados.totalcredu | formataDinheiro }}</span>
        </v-card-subtitle><v-card-subtitle v-else-if="dados.totalcredu == null" class="white--text font-weight-black text-center">
        <v-icon class="mr-2" size="30">mdi-cash</v-icon>
        <span>SALDO TOTAL DO CREDU A BAIXAR: {{ dados.totalcredu | formataDinheiro }}</span>
      </v-card-subtitle>

        <v-card-subtitle v-else class="white--text font-weight-black text-center">
          <span>CARREGANDO SALDO TOTAL DO CREDU A BAIXAR...</span>
        </v-card-subtitle>

        <v-divider class="white" />

        <v-card-text class="pa-3 pb-1">

          <v-progress-linear indeterminate absolute :active="carregando" />

          <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" light />
          <v-data-table v-else :headers="headers" :items="dados.lista" :expanded.sync="expanded"
                        :items-per-page="-1" item-key="compositeKey" class="elevation-0" expand-icon="mdi-menu-down"
                        @click:row="handleRowClick" :item-class="rowClass" mobile-breakpoint="0"
                        v-model="selecionadosAgrupado" hide-default-footer :show-select="false" light>

            <template v-slot:header.data-table-select>
              <v-checkbox :input-value="selectAllCheckedCredu" @click="toggleSelectAllCredu"
                          :indeterminate="isIndeterminate" :disabled="dadosTodosCliente.lista.length <= 0 || carregando || carregando2"></v-checkbox>
            </template>

            <template v-slot:item.idempresa="{ item }">
              <span class="text-truncate">{{ item.idempresa }}</span>
            </template>

            <template v-slot:item.codigo="{ item }">
              {{ item.codigo }}
            </template>

            <template v-slot:item.cliente="{ item }">
              {{ item.cliente }}
            </template>

            <template v-slot:item.saldotitulo="{ item }">
              {{ item.saldotitulo | formataDinheiro }}
            </template>

            <template v-slot:item.tipo="{ item }">
              {{ item.tipo }}
            </template>

            <template template v-slot:expanded-item="{}">
              <td colspan="10" class="px-0 primary">
                <v-simple-table class="table">
                  <thead class="header">
                  <tr class="cursor-normal">
                    <th nowrap>
                    </th>
                    <th nowrap>Titulo</th>
                    <th nowrap>Digito Titulo</th>
                    <th nowrap>Saldo Titulo</th>
                    <th nowrap>Juros Mora</th>
                    <th nowrap>Valor Atualizado</th>
                    <th nowrap>Tipo</th>
                  </tr>
                  </thead>
                  <tbody class="body text-capitalize">
                  <tr v-if="carregando2">
                    <td colspan="100%"><v-skeleton-loader light type="table-row" /></td>
                  </tr>
                  <tr v-for="(item, index) in dadosCliente" :key="index" class="cursor-normal"
                      v-else>
                    <td>
                      <v-checkbox :input-value="isItemSelected(item)"
                                  @change="toggleItem(item)" dense class="mt-n1" color="red darken-3"
                                  hide-details></v-checkbox>
                    </td>
                    <td nowrap>{{ item.titulo }}</td>
                    <td nowrap>{{ item.digitotitulo }}</td>
                    <td nowrap>{{ item.saldotitulo | formataDinheiro }}</td>
                    <td nowrap>{{ item.jurosmora | formataDinheiro }}</td>
                    <td nowrap>{{ item.valoratualizado | formataDinheiro }}</td>
                    <td nowrap>{{ item.tipo }}</td>
                  </tr>
                  </tbody>
                  <tfoot class="foot text-capitalize" v-if="!carregando2">
                  <tr class="cursor-normal">
                    <td nowrap></td>
                    <td nowrap></td>
                    <td nowrap class="bold-text">Totais:</td>
                    <td nowrap class="bold-text">
                      {{ dadosCliente.length > 0
                        ? dadosCliente.map(v => v.saldotitulo).reduce((a, v) => a + v, 0)
                        : 0 | formataDinheiro }}
                    </td>
                    <td nowrap class="bold-text">
                      {{ dadosCliente.length > 0
                        ? dadosCliente.map(v => v.jurosmora).reduce((a, v) => a + v, 0)
                        : 0 | formataDinheiro }}
                    </td>
                    <td nowrap class="bold-text">
                      {{ dadosCliente.length > 0
                        ? dadosCliente.map(v => v.valoratualizado).reduce((a, v) => a + v, 0)
                        : 0 | formataDinheiro }}
                    </td>
                    <td></td>
                  </tr>
                  </tfoot>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:footer>
              <v-divider v-if="dados.total > 20" />
              <v-progress-linear indeterminate absolute :active="carregando" />
              <Paginacao :class="dados.total > 20 ? 'my-2 pb-2' : ''" :total="dados.total"
                         @atualizar="listar" :carregando="carregando" :pg="pgSelecionada" />
            </template>
          </v-data-table>

        </v-card-text>


        <v-card-actions class="pa-1 pb-3 pr-3">
          <v-spacer />
          <v-btn color="green darken-1" elevation="0" class="px-5" @click="dialogVerifica = true"
                 :disabled="dadosTodosCliente.lista.length <= 0 || carregando || carregando2">
            Baixar
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import Paginacao from "../Widgets/Paginacao.vue";

export default {
  components: { Paginacao },
  name: "CreduLancamento",
  mixins: [mixinFilial],
  data: () => ({
    dialog: { status: false, dados: [], header: {} },
    dialog2: { status: false },
    alerta2: false,
    busca: {
      data: "",
      idfiliais: [],
    },
    datas: [],
    headers: [
      { text: '', value: 'data-table-select', sortable: false }, // Coluna do checkbox
      { text: 'Cód. Filial', value: 'idempresa', class: 'text-no-wrap' },
      { text: 'Cód Cliente', value: 'codigo', class: 'text-no-wrap' },
      { text: 'Cliente', value: 'cliente', class: 'text-no-wrap' },
      { text: 'Valor', value: 'saldotitulo', class: 'text-no-wrap' },
      { text: 'Tipo', value: 'tipo', class: 'text-no-wrap' },
    ],
    carregando: true,
    carregando2: true,
    carregandoSkeleton: true,
    selectAllChecked: false,
    selectAllCheckedCredu: false,
    todos: false,
    dialogVerifica: false,
    selecionados: [],
    selecionadosAgrupado: [],
    //selecionaTodos: [],
    dados: { lista: [], total: 0, totalcredu: 0 },
    dadosCliente: { lista: [] },
    dadosTodosCliente: null,
    v: false,
    expanded: [],
    cliente: {},
    pgSelecionada: 0,
    pagOn: false,
    anoAtual: new Date(),
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
              v.idfilial != 6 &&
              v.idfilial != 12 &&
              v.idfilial != 13 &&
              v.idfilial != 25 &&
              v.idfilial != 29 &&
              v.idfilial != 33 &&
              v.idfilial != 37 &&
              v.idfilial != 39 &&
              v.idfilial != 40 &&
              v.idfilial != 42
          );
        });
      } else {
        return [];
      }
    },
    selectAll() {
      return (this.selecionados.length === this.dadosTodosCliente.lista.length && this.selecionados.length > 0);
    },
    isIndeterminate() {
      return (
          this.selecionados.length > 0 &&
          this.selecionados.length < this.dadosTodosCliente.lista.length
      );
    },
  },
  methods: {
    toggleSelectAll(tipo) {
      if (tipo == 'CHECKBOX') {
        if (this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
      } else {
        if (!this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
        this.selectAllChecked = !this.selectAllChecked;
      }
    },
    toggleSelectAllCredu(tipo) {
      if (tipo == 'CHECKBOX') {
        if (!this.selectAllCheckedCredu) {
          // Se "Selecionar Todos" está desmarcado, seleciona todos os títulos
          this.selecionados = [...this.dadosTodosCliente.lista]; // Faz uma cópia completa
          //console.log('Todos os títulos selecionados:', this.selecionados);
        } else {
          // Se "Selecionar Todos" está marcado, desmarca todos
          this.selecionados = [];
        }
      } else {
        if (!this.selectAllCheckedCredu) {
          // Se "Selecionar Todos" está desmarcado, seleciona todos os títulos
          this.selecionados = [...this.dadosTodosCliente.lista]; // Faz uma cópia completa
          //console.log('Todos os títulos selecionados:', this.selecionados);
        } else {
          // Se "Selecionar Todos" está marcado, desmarca todos
          this.selecionados = [];
        }
      }

      // Alterna o estado do "Selecionar Todos"
      this.selectAllCheckedCredu = !this.selectAllCheckedCredu;
    },
    isItemSelected(item) {
      // Verifica se 'dadosCliente' é um array
      if (!Array.isArray(this.dadosCliente)) {
        //console.error('dadosCliente não está definido ou não é um array');
        return false;
      }

      // Verifica se o item específico está selecionado
      return this.selecionados.some(
          selected => selected.titulo === item.titulo && selected.digitotitulo === item.digitotitulo
      );
    },
    toggleItem(item) {
      // Verifica se 'dadosCliente' é um array
      if (!Array.isArray(this.dadosCliente)) {
        //console.error('dadosCliente não está definido ou não é um array');
        return;
      }

      // Verifica se o item específico está selecionado
      const isSelected = this.selecionados.some(
          selected => selected.titulo === item.titulo && selected.digitotitulo === item.digitotitulo
      );

      if (!isSelected) {
        // Adiciona o item aos selecionados se ele não estiver na lista
        this.selecionados.push(item);
      } else {
        // Remove o item dos selecionados se ele já estiver na lista
        const index = this.selecionados.findIndex(
            selected => selected.titulo === item.titulo && selected.digitotitulo === item.digitotitulo
        );
        if (index !== -1) {
          this.selecionados.splice(index, 1);
        }
      }
    },
    listar(pg) {
      this.pgSelecionada = pg;
      this.carregando = true;
      this.dados = { lista: [], total: 0, totalcredu: 0 }
      this.carregandoSkeleton = true;
      this.selecionados = [];
      this.selectAllCheckedCredu = false;
      this.expanded = [];
      return axios
          .post(`${this.backendUrl}credu/listar`, {
            data: this.busca.data,
            idempresa: this.busca.idfiliais.join(','),
            limit: this.pgLimit,
            offset: this.pgLimit * pg,
          })
          .then((res) => {
            this.dados.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
              const compositeKey = `${v.codigo}-${v.tipo}`;
              return {
                ...v,
                compositeKey: compositeKey,
              };
            });
            this.dados.total = res.data.total;
            this.dados.totalcredu = res.data.totalcredu;
            this.carregando = false;
            this.carregandoSkeleton = false;
            // Após buscar os clientes, chama a função para listar os dados detalhados
            this.listarClientes();
          })
          .catch(() => {
            this.dados = { lista: [], total: 0 };
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
    },
    listaClienteSelecionado(id) {
      // Busca o cliente pelo id dentro do array de clientes
      this.dadosCliente = this.dadosTodosCliente.lista.filter(cliente => cliente.compositeKey === id);
      //console.log(this.dadosTodosCliente.lista);
      // Verificação adicional para caso o cliente não seja encontrado
      if (!this.dadosCliente) {
        //console.log('Cliente não encontrado');

      } else {
        //console.log('Cliente encontrado:', this.dadosCliente);
        this.carregando = false;
        //this.carregando2 = false;
        this.carregandoSkeleton = false;
      }
    },
    listarClientes() {
      this.dadosTodosCliente = { lista: [] };
      this.carregando2 = true;
      return axios
          .post(`${this.backendUrl}credu/cliente/listar`, {
            data: this.busca.data,
            idempresa: this.busca.idfiliais.join(','),
          })
          .then((res) => {
            this.dadosTodosCliente.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
              const compositeKey = `${v.codigo}-${v.tipo}`;
              return {
                ...v,
                compositeKey: compositeKey,
              };
            });

            this.carregando = false;
            this.carregando2 = false;
            this.carregandoSkeleton = false;
          })
          .catch(() => {
            this.dadosTodosCliente = { lista: [] };
            this.carregando = false;
            this.carregando2 = false;
            this.carregandoSkeleton = false;
          });
    },
    periodos() {
      let ano = this.anoAtual.getFullYear();
      return axios
          .post(`${this.backendUrl}credu/data/listar`, {})
          .then((res) => {
            this.datas = res.data.lista
                .filter(v => v.anoinicio >= ano) // Evita valores undefined
                .map((v) => ({
                  ...v,
                  name: `${v.descricao}/${v.anotermino}`,
                  value: v.datatermino.split("T")[0],
                }));
          });
    },
    baixarTitulos() {
      this.carregando = true;
      let titulosABaixar = null;
      if (this.selecionados.length > 0) {
        titulosABaixar = this.selecionados
      } else {
        this.alerta2 = true
      }
      return axios
          .post(`${this.backendUrl}credu/baixa`, {
            titulos: titulosABaixar,
          })
          .then((res) => {
            this.alerta2 = false
            this.carregando = false;
            this.dialog2.status = true
            this.dialog2.title = 'Baixa de títulos bem sucedida'
            this.dialog2.msg = 'Os títulos foram baixados com sucesso!'
            this.dialog2.icon = 'mdi-check-circle-outline'
            this.dialog2.iconColor = 'success'
            this.dialogVerifica = false;
            console.log(res.data);
            this.listar(0);
          })
          .catch(() => {
            this.dialog2.status = true
            this.dialog2.title = 'Erro'
            this.dialog2.msg = 'Não foi possível baixar os títulos!'
            this.dialog2.icon = 'mdi-alert-outline'
            this.dialog2.iconColor = 'red'
            this.dialogVerifica = false;
            this.carregando = false;
            //console.log("Erro");
            this.listar(0);
          });
    },
    verificaData(dataIni, dataFim) {
      const hoje = new Date();
      const inicio = new Date(dataIni);
      const fim = new Date(dataFim);

      // Verifica se hoje está entre as datas
      return hoje >= inicio && hoje <= fim;
    },
    // Método para filtrar o período atual
    predefineData() {
      if (this.datas.length === 0) return; // Se não houver dados, não tente fazer a busca

      const periodoAtual = this.datas.find(data =>
          this.verificaData(data.datainicio, data.datatermino)
      );

      if (periodoAtual) {
        this.busca.data = periodoAtual.datatermino.split("T")[0];
      }
    },
    rowClass(item) {
      return this.expanded.includes(item) ? 'primary white--text' : '';
    },
    handleRowClick(item) {// toggleExpand(item)
      this.corIcon = true;
      if(this.dadosTodosCliente != null) {
        if (this.expanded.includes(item)) {
          // Se o item já estiver expandido, remove-o da lista de expandidos
          this.listaClienteSelecionado(item.compositeKey);
          this.expanded = this.expanded.filter(i => i !== item);
        } else {
          // Caso contrário, define a lista de expandidos para conter apenas o item atual
          this.listaClienteSelecionado(item.compositeKey);
          this.expanded = [item];
        }
      }
    },
    async init() {
      this.busca.idfiliais.push(this.usuario.idfilial);
      //this.busca.idfilial = this.usuario.idfilial;
      await this.periodos();
      await this.predefineData();
      await this.listar(0);
      await this.listarClientes();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.table {
  margin: 0px 0px 10px 0px;
}

.header th {
  background-color: #CFD8DC !important;
  height: 25px !important;
}

.body td {
  height: 20px !important;
  font-size: larger;
}

.bold-text {
  font-weight: bold;
}

.title-expanded {
  font-size: 18px
}

.foot td {
  height: 20px !important;
}
</style>