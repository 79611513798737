import { render, staticRenderFns } from "./EntregaGet.vue?vue&type=template&id=b1a1f074&scoped=true"
import script from "./EntregaGet.vue?vue&type=script&lang=js"
export * from "./EntregaGet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1a1f074",
  null
  
)

export default component.exports