import { render, staticRenderFns } from "./MarketingGerarPdf.vue?vue&type=template&id=50acf87e&scoped=true"
import script from "./MarketingGerarPdf.vue?vue&type=script&lang=js"
export * from "./MarketingGerarPdf.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50acf87e",
  null
  
)

export default component.exports