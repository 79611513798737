<template>
  <div>
    <v-snackbar :color="snackbar.color" v-model="snackbar.status" :timeout="snackbar.timeout" dark>
      <span class="font-weight-bold">{{ snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn class="font-weight-bold" color="blue-grey lighten-3" text v-bind="attrs"
          @click="snackbar.status = false">Fechar</v-btn>
      </template>
    </v-snackbar>
<!--    <v-col cols="12" class="pa-0">-->
<!--      <v-card>-->
<!--        <v-card-text>-->
<!--          <v-form>-->
<!--            <v-row class="mb-n10">-->
<!--              <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">-->
<!--                <v-col class="d-none d-md-block mt-n3 mb-3 px-2">-->
<!--                  <InputDatePicker-->
<!--                      :disabled="carregando"-->
<!--                      v-model="busca.dtinicio"-->
<!--                      label="Data inicial"-->
<!--                      :outlined="true"-->
<!--                      :dense="true"-->
<!--                  />-->
<!--                </v-col>-->
<!--                <v-col class="d-none d-md-block mt-n3 mb-3 px-2">-->
<!--                  <InputDatePicker-->
<!--                      :disabled="carregando"-->
<!--                      v-model="busca.dtfim"-->
<!--                      label="Data final"-->
<!--                      :outlined="true"-->
<!--                      :dense="true"-->
<!--                  />-->
<!--                </v-col>-->
<!--                <v-col class="d-none d-md-block mt-n3 mb-3 px-2">-->
<!--                  <v-autocomplete-->
<!--                      clearable-->
<!--                      :items="filiaisFiltro"-->
<!--                      item-text="filial"-->
<!--                      item-value="idfilial"-->
<!--                      label="Filial"-->
<!--                      outlined-->
<!--                      dense-->
<!--                      v-model="busca.idfilial"-->
<!--                      hide-details-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--                <v-col class="d-none d-md-block mt-n3 mb-3 px-2">-->
<!--                  <v-autocomplete-->
<!--                      clearable-->
<!--                      :items="[{flag: 'F', tipo:'Venda Futura'},{flag: 'A', tipo:'Venda Aguardando'}]"-->
<!--                      item-text="tipo"-->
<!--                      item-value="flag"-->
<!--                      label="Modelo de venda"-->
<!--                      outlined-->
<!--                      dense-->
<!--                      v-model="busca.flag"-->
<!--                      hide-details-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--                <v-bottom-sheet class="d-flex d-md-none px-0" v-model="sheet">-->
<!--                  <v-sheet class="text-center" height="100%">-->
<!--                    <v-row class="pa-3">-->
<!--                      <v-col cols="12" class="pr-3 pr-md-0 mb-n8">-->
<!--                        <v-select-->
<!--                            clearable-->
<!--                            :items="filiaisFiltro"-->
<!--                            item-text="filial"-->
<!--                            item-value="idfilial"-->
<!--                            label="Filial"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            v-model="busca.idfilial"-->
<!--                        ></v-select>-->
<!--                      </v-col>-->
<!--                      <v-col cols="6">-->
<!--                        <InputDatePicker-->
<!--                            v-model="busca.dtinicio"-->
<!--                            label="Data inicial"-->
<!--                            :outlined="true"-->
<!--                            :dense="true"-->
<!--                        />-->
<!--                      </v-col>-->
<!--                      <v-col cols="6">-->
<!--                        <InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />-->
<!--                      </v-col>-->
<!--                      <v-col cols="6">-->
<!--                        <v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>-->
<!--                      </v-col>-->
<!--                      <v-col cols="6">-->
<!--                        <v-btn text class="mt-n3" color="primary" @click="listar(), sheet = !sheet">Filtrar</v-btn>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-sheet>-->
<!--                </v-bottom-sheet>-->
<!--                <v-col class="mt-n3 mb-3 px-2">-->
<!--                  <v-text-field-->
<!--                      class="d-flex d-md-none"-->
<!--                      v-model="busca.busca"-->
<!--                      label="Buscar"-->
<!--                      placeholder="Numero de pedido ou código de cliente"-->
<!--                      outlined-->
<!--                      dense-->
<!--                      append-outer-icon="mdi-menu"-->
<!--                      @click:append-outer="sheet = !sheet"-->
<!--                      @keydown.enter="keydown = !keydown"-->
<!--                      hide-details-->
<!--                  />-->
<!--                  <v-text-field-->
<!--                      class="d-none d-md-block"-->
<!--                      v-model="busca.busca"-->
<!--                      label="Buscar"-->
<!--                      placeholder="Numero de pedido ou código de cliente"-->
<!--                      outlined-->
<!--                      dense-->
<!--                      @keydown.enter="keydown = !keydown"-->
<!--                      hide-details-->
<!--                  />-->
<!--                </v-col>-->
<!--                <v-btn-->
<!--                    :disabled="carregando"-->
<!--                    class="d-none d-md-block mt-1"-->
<!--                    color="primary"-->
<!--                    elevation="0"-->
<!--                    fab-->
<!--                    x-small-->
<!--                    @click="listar()"-->
<!--                >-->
<!--                  <v-icon>mdi-magnify</v-icon>-->
<!--                </v-btn>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-form>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </v-col>-->
    <v-col cols="12" class="pa-0">
      <v-card flat dark color="primary" class="rounded-2">
        <v-card-subtitle class="pt-2 pb-3 px-0">
          <v-tabs v-model="tab" grow>
            <v-tab @click="listar()" href="#tab-1" class="primary text--white">
              <v-icon class="mb-1">mdi-cash-clock</v-icon>
              <span class="ml-2 d-none d-md-block">Lançar Remissões de Vendas</span>
            </v-tab>
            <v-tooltip bottom color="error" open-delay="100" content-class='custom-tooltip' class="px-5">
              <template v-slot:activator="{ on, attrs }">
                <v-row v-on="on" v-bind="attrs" class="d-flex">
                  <v-tab @click="listar()" href="#tab-2" class="primary text--white ml-3"
                    :disabled="!permissao('remissao_historico')">
                    <v-icon>mdi-history</v-icon>
                    <span class="ml-2 d-none d-md-block">Histórico de remissões de vendas</span>
                  </v-tab>
                </v-row>
              </template>
              <span>Acesso exclusivo para a equipe de controladoria.</span>
            </v-tooltip>
          </v-tabs>
        </v-card-subtitle>
        <v-card-text>
          <v-tabs-items v-model="tab" class="pa-0">
            <v-tab-item value="tab-1">
              <v-card-subtitle>
                <v-form>
                  <v-row class="mb-n10">
                    <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <InputDatePicker :disabled="carregando" v-model="busca.dtinicio" label="Data inicial"
                          :outlined="true" :dense="true" />
                      </v-col>
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
                          :outlined="true" :dense="true" />
                      </v-col>
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <v-select :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial"
                          outlined dense v-model="busca.idfiliais" hide-details multiple class="single-line-selection">
                          <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">{{ item.filial }}</span>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ busca.idfiliais.length - 1 }})
                            </span>
                          </template>

                          <template #prepend-item>
                            <v-list-item class="pr-0" dense>
                              <v-list-item-action>
                                <v-checkbox v-model="selectAllChecked"
                                  @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content @click="toggleSelectAll('LABEL')">
                                <!-- Use uma tag label para vincular ao checkbox -->
                                <label class="text-subtitle-2">Todas</label>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <v-autocomplete clearable
                          :items="[{ flag: 'F', tipo: 'Venda Futura' }, { flag: 'A', tipo: 'Venda Aguardando' }]"
                          item-text="tipo" item-value="flag" label="Modelo de venda" outlined dense v-model="busca.flag"
                          hide-details></v-autocomplete>
                      </v-col>
                      <v-bottom-sheet class="d-flex d-md-none px-0" v-model="sheet">
                        <v-sheet class="text-center" height="100%">
                          <v-row class="pa-3">
                            <v-col cols="12" class="pr-3 pr-md-0 mb-n8">
                              <v-select :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial"
                                outlined dense v-model="busca.idfiliais" hide-details multiple
                                class="single-line-selection">
                                <template v-slot:selection="{ item, index }">
                                  <span v-if="index === 0">{{ item.filial }}</span>
                                  <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ busca.idfiliais.length - 1 }})
                                  </span>
                                </template>

                                <template #prepend-item>
                                  <v-list-item class="pr-0" dense>
                                    <v-list-item-action>
                                      <v-checkbox v-model="selectAllChecked"
                                        @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content @click="toggleSelectAll('LABEL')">
                                      <!-- Use uma tag label para vincular ao checkbox -->
                                      <label class="text-subtitle-2">Todas</label>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="6">
                              <InputDatePicker v-model="busca.dtinicio" label="Data inicial" :outlined="true"
                                :dense="true" />
                            </v-col>
                            <v-col cols="6">
                              <InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true"
                                :dense="true" />
                            </v-col>
                            <v-col cols="6">
                              <v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
                            </v-col>
                            <v-col cols="6">
                              <v-btn text class="mt-n3" color="primary"
                                @click="listar(), sheet = !sheet">Filtrar</v-btn>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-bottom-sheet>
                      <v-col class="mt-n3 mb-3 px-2">
                        <v-text-field class="d-flex d-md-none" v-model="busca.busca" label="Buscar"
                          placeholder="Nº pedido ou códido cliente" outlined dense append-outer-icon="mdi-menu"
                          @click:append-outer="sheet = !sheet" @keydown.enter="keydown = !keydown" hide-details />
                        <v-text-field class="d-none d-md-block" v-model="busca.busca" label="Buscar"
                          placeholder="Nº pedido ou códido cliente" outlined dense @keydown.enter="keydown = !keydown"
                          hide-details />
                      </v-col>
                      <v-btn :disabled="carregando" class="d-none d-md-block mt-1" color="primary" elevation="0" fab
                        x-small @click="listar()">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-subtitle>
              <v-divider />
              <v-card-subtitle class="primary--text font-weight-medium text-center py-2 pr-8">
                <span>SALDO TOTAL DAS REMISSÕES: {{ dados.total | formataDinheiro }}</span>
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="pa-0 elevation-0">
                <v-skeleton-loader v-if="carregando" type="table-tbody" />
                <v-data-table v-else :headers="[
                  { text: 'Filial', value: 'idfilial', class: 'text-no-wrap' },
                  { text: 'N° Documento', value: 'idpedido', class: 'text-no-wrap' },
                  { text: 'Nº Cliente', value: 'idclifor', class: 'text-no-wrap' },
                  { text: 'Cliente', value: 'cliente', class: 'text-no-wrap' },
                  { text: 'Valor original pendente', value: 'valor', class: 'text-no-wrap' },
                  { text: 'Data da venda', value: 'datavenda', class: 'text-no-wrap', sortable: false },
                  { text: 'Modelo', value: 'modelo', class: 'text-no-wrap' },
                  { text: 'Realizar', value: 'remeter', class: 'text-no-wrap', align: 'center', sortable: false },
                  { text: 'Bloqueio / Liberação', value: 'botaoBloqueaRem', class: 'text-no-wrap', align: 'center', sortable: false },
                  { text: 'Observação', value: 'observacao', class: 'text-no-wrap', align: 'center', sortable: false },
                ]" :items="dados.lista" :expanded.sync="expanded" expand-icon="mdi-menu-down" item-key="idpedido"
                  class="elevation-0" :items-per-page="50" @click:row="handleRowClick" single-expand
                  :item-class="rowClass" mobile-breakpoint="0" :footer-props="{
                    showFirstLastPage: true,
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    itemsPerPageText: 'Pedidos por página',
                    'items-per-page-options': [30, 50, 100, 500],
                    disableItemsPerPage: false,
                  }">

                  <template v-slot:header.botaoBloqueaRem="{ header }" v-if="permissao('remissao_lancar')">
                    <v-tooltip right color="red" content-class='custom-tooltip'>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ header.text }}</span>
                      </template>
                      <span>Funcionalidade de uso exclusivo da equipe da Controladoria</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:header.observacao="{ header }" v-if="permissao('remissao_lancar')">
                    <v-tooltip right color="red" content-class='custom-tooltip'>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ header.text }}</span>
                      </template>
                      <span>Acesso exclusivo para equipe da Controladoria</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.idfilial="{ item }">
                    <span class="text-truncate">{{ item.idfilial }} - {{ item.empresa }}</span>
                  </template>

                  <template v-slot:item.cliente="{ item }">
                    <v-tooltip right color="primary" content-class='custom-tooltip'>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ item.cliente | formataTextoPedido }}</span>
                      </template>
                      <span>{{ item.cliente }}</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.datavenda="{ item }">
                    {{ item.datavenda | formataData }}
                  </template>

                  <template v-slot:item.valor="{ item }">
                    {{ item.valor | formataDinheiro }}
                  </template>

                  <template v-slot:item.remeter="{ item }">
                    <v-btn :disabled="item.bloqueia === true" elevation="1"
                      @click.stop="listarProdutos(item), dialog = true" text x-small
                      :class="!expanded.includes(item) ? 'primary' : 'white'"><v-icon
                        class="pr-1">mdi-cash-sync</v-icon> Remissão </v-btn>
                  </template>

                  <!--            { text: 'Expandir', value: 'expand', sortable: false },-->
                  <!--            <template v-slot:item="{ item }">-->
                  <!--              <tr>-->
                  <!--                <td>{{  item.idempresa }}</td>-->
                  <!--                <td>{{  item.empresa }}</td>-->
                  <!--                <td>{{  item.idpedido }}</td>-->
                  <!--                <td>{{  item.idclifor }}</td>-->
                  <!--                <td>{{  item.cliente }}</td>-->
                  <!--                <td>{{  item.dtbaixa }}</td>-->
                  <!--                <td>{{  item.valor }}</td>-->
                  <!--                <td><v-btn @click="toggleExpand(item)"><v-icon>{{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>{{ expanded.includes(item) ? 'Esconder' : 'Produtos' }} </v-btn></td>-->
                  <!--              </tr>-->
                  <!--            </template>-->

                  <template v-slot:expanded-item="{ }">
                    <td colspan="10" class="px-0 primary">

                      <v-simple-table class="table">
                        <thead class="header">
                          <tr class="cursor-normal">
                            <th nowrap>Cód. produto</th>
                            <th nowrap>Descrição produto</th>
                            <th nowrap>Qtd. baixada</th>
                            <th nowrap>Valor UN. praticado</th>
                            <th nowrap>Valor total praticado</th>
                            <th nowrap>Valor UN. atual</th>
                            <th nowrap>Valor total atual</th>
                          </tr>
                        </thead>
                        <tbody class="body text-capitalize">
                          <tr v-if="carregando2">
                            <td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td>
                          </tr>
                          <tr v-for="(item, index) in dados2" :key="index" class="cursor-normal" v-else>
                            <td nowrap>{{ item.idsubproduto }}</td>
                            <td nowrap>{{ item.produto | formataTextoPedidoConcreto }}</td>
                            <td nowrap>{{ item.qtdsaldodevolucao }}</td>
                            <td nowrap>{{ item.valorpraticado | formataDinheiro }}</td>
                            <td nowrap>{{ item.valorsaldopraticado | formataDinheiro }}</td>
                            <td nowrap>{{ item.valoratual | formataDinheiro }}</td>
                            <td nowrap>{{ item.valorsaldoatual | formataDinheiro }}</td>
                          </tr>
                        </tbody>
                        <tfoot class="foot text-capitalize" v-if="dados2[0] && !carregando2">
                          <tr class="cursor-normal">
                            <td nowrap></td>
                            <td nowrap></td>
                            <td nowrap>{{ dados2.map(v => v.qtdsaldodevolucao).reduce((a, v) => a + v) }}</td>
                            <td nowrap>{{ dados2.map(v => v.valorpraticado).reduce((a, v) => a + v) | formataDinheiro }}
                            </td>
                            <td nowrap>{{ dados2.map(v => v.valorsaldopraticado).reduce((a, v) => a + v) |
                              formataDinheiro }}</td>
                            <td nowrap>{{ dados2.map(v => v.valoratual).reduce((a, v) => a + v) | formataDinheiro }}
                            </td>
                            <td nowrap>{{ dados2.map(v => v.valorsaldoatual).reduce((a, v) => a + v) | formataDinheiro
                              }}
                            </td>
                          </tr>
                        </tfoot>
                      </v-simple-table>
                    </td>
                  </template>

                  <template v-slot:item.botaoBloqueaRem="{ item }" v-if="permissao('remissao_lancar')">
                    <v-btn :disabled="!permissao('remissao_lancar' || carregando)" @click="editaRemissao(item)"
                      :class="[item.bloqueia ? 'green' : 'red', 'white--text']" small>
                      <v-icon class="mr-2" color="white" size="18">{{ item.bloqueia ? 'mdi-check-bold' :
                        'mdi-block-helper'}}</v-icon>
                      {{ item.bloqueia ? 'Liberar' : 'Bloquear' }}
                    </v-btn>
                  </template>

                  <template v-slot:item.observacao="{ item }" v-if="permissao('remissao_lancar')">
                    {{ item.observacao }}
                  </template>


                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card-subtitle>
                <v-form>
                  <v-row class="mb-n10">
                    <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <InputDatePicker :disabled="carregando" v-model="busca.dtinicio" label="Data inicial"
                          :outlined="true" :dense="true" />
                      </v-col>
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
                          :outlined="true" :dense="true" />
                      </v-col>
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <v-select :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial"
                          outlined dense v-model="busca.idfiliais" hide-details multiple class="single-line-selection">
                          <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">{{ item.filial }}</span>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ busca.idfiliais.length - 1 }})
                            </span>
                          </template>

                          <template #prepend-item>
                            <v-list-item class="pr-0" dense>
                              <v-list-item-action>
                                <v-checkbox v-model="selectAllChecked"
                                  @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content @click="toggleSelectAll('LABEL')">
                                <!-- Use uma tag label para vincular ao checkbox -->
                                <label class="text-subtitle-2">Todas</label>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col class="d-none d-md-block mt-n3 mb-3 px-2">
                        <v-autocomplete clearable
                          :items="[{ flag: 'F', tipo: 'Venda Futura' }, { flag: 'A', tipo: 'Venda Aguardando' }]"
                          item-text="tipo" item-value="flag" label="Modelo de venda" outlined dense v-model="busca.flag"
                          hide-details></v-autocomplete>
                      </v-col>
                      <v-bottom-sheet class="d-flex d-md-none px-0" v-model="sheet">
                        <v-sheet class="text-center" height="100%">
                          <v-row class="pa-3">
                            <v-col cols="12" class="pr-3 pr-md-0 mb-n8">
                              <v-select :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial"
                                outlined dense v-model="busca.idfiliais" hide-details multiple
                                class="single-line-selection">
                                <template v-slot:selection="{ item, index }">
                                  <span v-if="index === 0">{{ item.filial }}</span>
                                  <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ busca.idfiliais.length - 1 }})
                                  </span>
                                </template>

                                <template #prepend-item>
                                  <v-list-item class="pr-0" dense>
                                    <v-list-item-action>
                                      <v-checkbox v-model="selectAllChecked"
                                        @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content @click="toggleSelectAll('LABEL')">
                                      <!-- Use uma tag label para vincular ao checkbox -->
                                      <label class="text-subtitle-2">Todas</label>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="6">
                              <InputDatePicker v-model="busca.dtinicio" label="Data inicial" :outlined="true"
                                :dense="true" />
                            </v-col>
                            <v-col cols="6">
                              <InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true"
                                :dense="true" />
                            </v-col>
                            <v-col cols="6">
                              <v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
                            </v-col>
                            <v-col cols="6">
                              <v-btn text class="mt-n3" color="primary"
                                @click="listar(), sheet = !sheet">Filtrar</v-btn>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-bottom-sheet>
                      <v-col class="mt-n3 mb-3 px-2">
                        <v-text-field class="d-flex d-md-none" v-model="busca.busca" label="Buscar"
                          placeholder="Nº pedido ou códido cliente" outlined dense append-outer-icon="mdi-menu"
                          @click:append-outer="sheet = !sheet" @keydown.enter="keydown = !keydown" hide-details />
                        <v-text-field class="d-none d-md-block" v-model="busca.busca" label="Buscar"
                          placeholder="Nº pedido ou códido cliente" outlined dense @keydown.enter="keydown = !keydown"
                          hide-details />
                      </v-col>
                      <v-btn :disabled="carregando" class="d-none d-md-block mt-1" color="primary" elevation="0" fab
                        x-small @click="listar()">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-subtitle>
              <v-divider />
              <v-card-subtitle class="primary--text font-weight-medium text-center py-2 pr-8">
                <span>VALOR TOTAL BAIXADO: {{ dados.total | formataDinheiro }}</span>
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="pa-0 elevation-0">
                <v-skeleton-loader v-if="carregando" type="table-tbody" />
                <v-data-table v-else :headers="[
                  { text: 'Filial', value: 'idfilial', class: 'text-no-wrap' },
                  { text: 'N° Documento', value: 'idpedido', class: 'text-no-wrap' },
                  { text: 'Cliente', value: 'cliente', class: 'text-no-wrap' },
                  { text: 'Valor baixado', value: 'valor', class: 'text-no-wrap' },
                  { text: 'Data/Hora da remissão', value: 'dtbaixa', class: 'text-no-wrap', sortable: false },
                  { text: 'Usuário', value: 'usuario', class: 'text-no-wrap', align: 'center', sortable: false },
                  { text: 'Nº novo pedido', value: 'idpedidonovo', class: 'text-no-wrap', align: 'center', sortable: false },
                  { text: 'Realizado', value: 'tipotransacao', class: 'text-no-wrap', align: 'center', sortable: false },
                  { text: '', value: 'admin', class: 'text-no-wrap', align: 'center', sortable: false },
                ]" :items="dados.lista" :expanded.sync="expanded" expand-icon="mdi-menu-down" item-key="idpedido"
                  class="elevation-0" :items-per-page="50" @click:row="handleRowClick" single-expand
                  :item-class="rowClass" mobile-breakpoint="0" :footer-props="{
                    showFirstLastPage: true,
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    itemsPerPageText: 'Pedidos por página',
                    'items-per-page-options': [30, 50, 100, 500],
                    disableItemsPerPage: false,
                  }">
                  <template v-slot:item.idfilial="{ item }">
                    <span class="text-truncate">{{ item.idfilial }} - {{ item.empresa }}</span>
                  </template>

                  <template v-slot:item.cliente="{ item }">
                    <v-tooltip right color="primary" content-class='custom-tooltip'>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="text-no-wrap">{{ item.idclifor }} - {{ item.cliente | formataTextoPedido
                          }}</span>
                      </template>
                      <span>{{ item.cliente }}</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.dtbaixa="{ item }">
                    {{ item.dtbaixa | formataData }}
                  </template>

                  <template v-slot:item.valor="{ item }">
                    {{ item.valor | formataDinheiro }}
                  </template>

                  <template v-slot:item.usuario="{ item }">
                    <span class="text-no-wrap">{{ item.idusuario }} - {{ item.usuario }}</span>
                  </template>

                  <template v-slot:item.tipotransacao="{ item }">
                    <v-chip v-if="item.tipotransacao == 'Crédito de Devolução'" small
                      :class="!expanded.includes(item) ? 'error mr-1' : 'white mr-1'"><span
                        :class="expanded.includes(item) ? 'error--text mr-1' : 'white--text mr-1'">Crédito de
                        Devolução</span></v-chip>
                    <v-chip v-else small :class="!expanded.includes(item) ? 'warning mr-1' : 'white mr-1'"><span
                        :class="expanded.includes(item) ? 'warning--text mr-1' : 'white--text mr-1'">Novo pedido de
                        venda</span></v-chip>
                  </template>

                  <template v-slot:item.admin="{ item }">
                    <div style="white-space: nowrap;">
                      <v-tooltip color="primary" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click.stop="listarTabelas(item), dialogTebelas = true" v-bind="attrs" v-on="on" icon
                            small :class="!expanded.includes(item) ? 'primary mr-1' : 'white mr-1'">
                            <v-icon size="19"
                              :color="expanded.includes(item) ? 'primary' : 'white'">mdi-table-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Visualizar tabelas</span>
                      </v-tooltip>

                      <v-tooltip color="error" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="!permissao('remissao_deletar') || carregando"
                            @click.stop="confirmaOperacao(item.tipotransacao), listarProdutos(item)" v-bind="attrs"
                            v-on="on" icon small :class="!expanded.includes(item) ? 'error' : 'white'">
                            <v-icon size="20"
                              :color="expanded.includes(item) ? 'error' : 'white'">mdi-undo-variant</v-icon>
                          </v-btn>
                        </template>
                        <span>Desfazer remissão</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <!--            { text: 'Expandir', value: 'expand', sortable: false },-->
                  <!--            <template v-slot:item="{ item }">-->
                  <!--              <tr>-->
                  <!--                <td>{{  item.idempresa }}</td>-->
                  <!--                <td>{{  item.empresa }}</td>-->
                  <!--                <td>{{  item.idpedido }}</td>-->
                  <!--                <td>{{  item.idclifor }}</td>-->
                  <!--                <td>{{  item.cliente }}</td>-->
                  <!--                <td>{{  item.dtbaixa }}</td>-->
                  <!--                <td>{{  item.valor }}</td>-->
                  <!--                <td><v-btn @click="toggleExpand(item)"><v-icon>{{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>{{ expanded.includes(item) ? 'Esconder' : 'Produtos' }} </v-btn></td>-->
                  <!--              </tr>-->
                  <!--            </template>-->

                  <template v-slot:expanded-item="{}">
                    <td colspan="10" class="px-0 primary">
                      <v-simple-table class="table">
                        <thead class="header">
                          <tr class="cursor-normal">
                            <th nowrap>Cód. produto</th>
                            <th nowrap>Descrição produto</th>
                            <th nowrap>Qtd. devolvida</th>
                            <th nowrap>Valor UN. praticado</th>
                            <th nowrap>Valor total praticado</th>
                            <th nowrap>Valor UN. devolvida</th>
                            <th nowrap>Valor total devolvida</th>
                          </tr>
                        </thead>
                        <tbody class="body text-capitalize">
                          <tr v-if="carregando2">
                            <td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td>
                          </tr>
                          <tr v-for="(item, index) in dados2" :key="index" class="cursor-normal" v-else>
                            <td nowrap>{{ item.idsubproduto }}</td>
                            <td nowrap>{{ item.produto | formataTextoPedidoConcreto }}</td>
                            <td nowrap>{{ item.qtdsaldodevolucao }}</td>
                            <td nowrap>{{ item.valorpraticado | formataDinheiro }}</td>
                            <td nowrap>{{ item.valorsaldopraticado | formataDinheiro }}</td>
                            <td nowrap>{{ item.valoratual | formataDinheiro }}</td>
                            <td nowrap>{{ item.valorsaldoatual | formataDinheiro }}</td>
                          </tr>
                        </tbody>
                        <tfoot class="foot text-capitalize" v-if="dados2[0] && !carregando2">
                          <tr class="cursor-normal">
                            <td nowrap></td>
                            <td nowrap></td>
                            <td nowrap>{{ dados2.map(v => v.qtdsaldodevolucao).reduce((a, v) => a + v) }}</td>
                            <td nowrap>{{ dados2.map(v => v.valorpraticado).reduce((a, v) => a + v) | formataDinheiro }}
                            </td>
                            <td nowrap>{{ dados2.map(v => v.valorsaldopraticado).reduce((a, v) => a + v) |
                              formataDinheiro }}</td>
                            <td nowrap>{{ dados2.map(v => v.valoratual).reduce((a, v) => a + v) | formataDinheiro }}
                            </td>
                            <td nowrap>{{ dados2.map(v => v.valorsaldoatual).reduce((a, v) => a + v) | formataDinheiro
                              }}
                            </td>
                          </tr>
                        </tfoot>
                      </v-simple-table>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
    <!--    <v-col v-if="!telaHistorico" cols="12" class="pa-0 pt-4">-->
<!--      <v-card color="primary" light>-->
<!--        <v-card-title class="white&#45;&#45;text">-->
<!--          Vendas Futuras e Aguardando-->
<!--        </v-card-title>-->
<!--        <v-divider/>-->
<!--        <v-card-text class="pt-0 elevation-0">-->
<!--          <v-data-table-->
<!--              :headers="[-->
<!--                {text: 'Filial', value: 'idfilial',class:'text-no-wrap' },-->
<!--                {text: 'N° Documento', value: 'idpedido',class:'text-no-wrap' },-->
<!--                {text: 'Nº Cliente', value: 'idclifor', class:'text-no-wrap'  },-->
<!--                {text: 'Cliente', value: 'cliente', class:'text-no-wrap'  },-->
<!--                {text: 'Data da venda', value: 'databaixa', class:'text-no-wrap', sortable:false  },-->
<!--                {text: 'Valor da baixa', value: 'valor', class:'text-no-wrap'  },-->
<!--                {text: 'Modelo', value: 'modelo', class:'text-no-wrap'  },-->
<!--                {text: 'Realizar', value: 'remeter', class:'text-no-wrap', align:'center', sortable:false  },-->
<!--              ]"-->
<!--              :items="dados"-->
<!--              :expanded.sync="expanded"-->
<!--              expand-icon="mdi-menu-down"-->
<!--              item-key="idpedido"-->
<!--              class="elevation-0"-->
<!--              :items-per-page="50"-->
<!--              @click:row="handleRowClick"-->
<!--              single-expand-->
<!--              :item-class="rowClass"-->
<!--              mobile-breakpoint="0"-->
<!--              :footer-props="{-->
<!--                showFirstLastPage: true,-->
<!--                prevIcon: 'mdi-chevron-left',-->
<!--                nextIcon: 'mdi-chevron-right',-->
<!--                itemsPerPageText: 'Pedidos por página',-->
<!--                'items-per-page-options': [30, 50, 100],-->
<!--                disableItemsPerPage: false,-->
<!--             }"-->
<!--          >-->
<!--            <template v-slot:item.idfilial="{ item }">-->
<!--              <span class="text-truncate">{{  item.idfilial }} - {{  item.empresa }}</span>-->
<!--            </template>-->

<!--            <template v-slot:item.cliente="{ item }">-->
<!--              <v-tooltip right color="primary" content-class='custom-tooltip'>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <span v-on="on">{{  item.cliente | formataTextoPedido }}</span>-->
<!--                </template>-->
<!--                <span>{{  item.cliente }}</span>-->
<!--              </v-tooltip>-->
<!--            </template>-->

<!--            <template v-slot:item.dtbaixa="{ item }">-->
<!--                {{  item.dtbaixa | formataData }}-->
<!--            </template>-->

<!--            <template v-slot:item.valor="{ item }">-->
<!--                {{  item.valor | formataDinheiro }}-->
<!--            </template>-->

<!--            <template v-slot:item.remeter="{ item }">-->
<!--              <v-btn :disabled="!permissao('remissao_lancar')" elevation="1" @click.stop="listarProdutos(item), dialog = true" text x-small :class="!expanded.includes(item) ? 'primary' : 'white'"><v-icon class="pr-1">mdi-cash-sync</v-icon> Remissão </v-btn>-->
<!--            </template>-->

<!--&lt;!&ndash;            { text: 'Expandir', value: 'expand', sortable: false },&ndash;&gt;-->
<!--&lt;!&ndash;            <template v-slot:item="{ item }">&ndash;&gt;-->
<!--&lt;!&ndash;              <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                <td>{{  item.idempresa }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                <td>{{  item.empresa }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                <td>{{  item.idpedido }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                <td>{{  item.idclifor }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                <td>{{  item.cliente }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                <td>{{  item.dtbaixa }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                <td>{{  item.valor }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                <td><v-btn @click="toggleExpand(item)"><v-icon>{{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>{{ expanded.includes(item) ? 'Esconder' : 'Produtos' }} </v-btn></td>&ndash;&gt;-->
<!--&lt;!&ndash;              </tr>&ndash;&gt;-->
<!--&lt;!&ndash;            </template>&ndash;&gt;-->

<!--            <template v-slot:expanded-item="{ }">-->
<!--              <td colspan="10" class="px-0 primary">-->
<!--                <v-simple-table class="table">-->
<!--                  <thead class="header">-->
<!--                    <tr class="cursor-normal">-->
<!--                      <th nowrap>Cód. produto</th>-->
<!--                      <th nowrap>Descrição produto</th>-->
<!--                      <th nowrap>Qtd. baixada</th>-->
<!--                      <th nowrap>Valor UN. praticado</th>-->
<!--                      <th nowrap>Valor total praticado</th>-->
<!--                      <th nowrap>Valor UN. atual</th>-->
<!--                      <th nowrap>Valor total atual</th>-->
<!--                    </tr>-->
<!--                  </thead>-->
<!--                  <tbody class="body text-capitalize">-->
<!--                    <tr v-for="(item, index) in dados2" :key="index" class="cursor-normal">-->
<!--                      <td nowrap>{{ item.idsubproduto }}</td>-->
<!--                      <td nowrap>{{ item.produto | formataTextoPedidoConcreto}}</td>-->
<!--                      <td nowrap>{{ item.qtdsaldodevolucao  }}</td>-->
<!--                      <td nowrap>{{ item.valorpraticado | formataDinheiro }}</td>-->
<!--                      <td nowrap>{{ item.valorsaldopraticado | formataDinheiro }}</td>-->
<!--                      <td nowrap>{{ item.valoratual | formataDinheiro }}</td>-->
<!--                      <td nowrap>{{ item.valorsaldoatual | formataDinheiro }}</td>-->
<!--                    </tr>-->
<!--                  </tbody>-->
<!--                  <tfoot class="foot text-capitalize" v-if="dados2[0]">-->
<!--                    <tr class="cursor-normal">-->
<!--                      <td nowrap></td>-->
<!--                      <td nowrap></td>-->
<!--                      <td nowrap>{{ dados2.map(v => v.qtdsaldodevolucao).reduce((a,v) => a+v)  }}</td>-->
<!--                      <td nowrap>{{ dados2.map(v => v.valorpraticado).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                      <td nowrap>{{ dados2.map(v => v.valorsaldopraticado).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                      <td nowrap>{{ dados2.map(v => v.valoratual).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                      <td nowrap>{{ dados2.map(v => v.valorsaldoatual).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                    </tr>-->
<!--                  </tfoot>-->
<!--                </v-simple-table>-->
<!--              </td>-->
<!--            </template>-->
<!--          </v-data-table>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </v-col>-->
<!--    <v-col v-else cols="12" class="pa-0 pt-4">-->
<!--      <v-card color="primary" light>-->
<!--        <v-card-title class="white&#45;&#45;text">-->
<!--          Hitórico de remissões de vendas-->
<!--        </v-card-title>-->
<!--        <v-divider/>-->
<!--        <v-card-text class="pt-0 elevation-0">-->
<!--          <v-data-table-->
<!--              :headers="[-->
<!--                {text: 'Filial', value: 'idfilial',class:'text-no-wrap' },-->
<!--                {text: 'N° Documento', value: 'idpedido',class:'text-no-wrap' },-->
<!--                {text: 'Nº Cliente', value: 'idclifor', class:'text-no-wrap'  },-->
<!--                {text: 'Cliente', value: 'cliente', class:'text-no-wrap'  },-->
<!--                {text: 'Data da remissão', value: 'databaixa', class:'text-no-wrap', sortable:false  },-->
<!--                {text: 'Valor da remissão', value: 'valor', class:'text-no-wrap'  },-->
<!--                {text: 'Modelo', value: 'modelo', class:'text-no-wrap'  },-->
<!--                {text: 'Realizado', value: 'realizado', class:'text-no-wrap', align:'center', sortable:false  },-->
<!--                {text: '', value: 'admin', class:'text-no-wrap', align:'center', sortable:false  },-->
<!--              ]"-->
<!--              :items="dados"-->
<!--              :expanded.sync="expanded"-->
<!--              expand-icon="mdi-menu-down"-->
<!--              item-key="idpedido"-->
<!--              class="elevation-0"-->
<!--              :items-per-page="50"-->
<!--              @click:row="handleRowClick"-->
<!--              single-expand-->
<!--              :item-class="rowClass"-->
<!--              mobile-breakpoint="0"-->
<!--              :footer-props="{-->
<!--                showFirstLastPage: true,-->
<!--                prevIcon: 'mdi-chevron-left',-->
<!--                nextIcon: 'mdi-chevron-right',-->
<!--                itemsPerPageText: 'Pedidos por página',-->
<!--                'items-per-page-options': [30, 50, 100],-->
<!--                disableItemsPerPage: false,-->
<!--             }"-->
<!--          >-->
<!--            <template v-slot:item.idfilial="{ item }">-->
<!--              <span class="text-truncate">{{  item.idfilial }} - {{  item.empresa }}</span>-->
<!--            </template>-->

<!--            <template v-slot:item.cliente="{ item }">-->
<!--              <v-tooltip right color="primary" content-class='custom-tooltip'>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <span v-on="on">{{  item.cliente | formataTextoPedido }}</span>-->
<!--                </template>-->
<!--                <span>{{  item.cliente }}</span>-->
<!--              </v-tooltip>-->
<!--            </template>-->

<!--            <template v-slot:item.dtbaixa="{ item }">-->
<!--              {{  item.dtbaixa | formataData }}-->
<!--            </template>-->

<!--            <template v-slot:item.valor="{ item }">-->
<!--              {{  item.valor | formataDinheiro }}-->
<!--            </template>-->

<!--            <template v-slot:item.realizado="{ item }">-->
<!--              <v-chip v-if="!item.realizado" small :class="!expanded.includes(item) ? 'error mr-1' : 'white mr-1'"><span :class="expanded.includes(item) ? 'error&#45;&#45;text mr-1' : 'white&#45;&#45;text mr-1'">Crédito de Devolução</span></v-chip>-->
<!--              <v-chip v-else small :class="!expanded.includes(item) ? 'warning mr-1' : 'white mr-1'"><span :class="expanded.includes(item) ? 'warning&#45;&#45;text mr-1' : 'white&#45;&#45;text mr-1'">Remessa para Logistica</span></v-chip>-->
<!--            </template>-->

<!--            <template v-slot:item.admin="{ item }">-->
<!--              <v-tooltip  color="primary" bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn @click.stop="listarTabelas(item), dialogTebelas = true" v-bind="attrs" v-on="on" icon small :class="!expanded.includes(item) ? 'primary mr-1' : 'white mr-1'">-->
<!--                    <v-icon size="19" :color="expanded.includes(item) ? 'primary' : 'white'">mdi-table-eye</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>Visualizar tabelas</span>-->
<!--              </v-tooltip>-->

<!--              <v-tooltip  color="error" bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn @click.stop="confirmaOperacao('E'), listarProdutos(item)"  v-bind="attrs" v-on="on" icon small :class="!expanded.includes(item) ? 'error' : 'white'">-->
<!--                    <v-icon size="20" :color="expanded.includes(item) ? 'error' : 'white'">mdi-undo-variant</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>Desfazer remissão</span>-->
<!--              </v-tooltip>-->
<!--            </template>-->

<!--            &lt;!&ndash;            { text: 'Expandir', value: 'expand', sortable: false },&ndash;&gt;-->
<!--            &lt;!&ndash;            <template v-slot:item="{ item }">&ndash;&gt;-->
<!--            &lt;!&ndash;              <tr>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>{{  item.idempresa }}</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>{{  item.empresa }}</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>{{  item.idpedido }}</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>{{  item.idclifor }}</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>{{  item.cliente }}</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>{{  item.dtbaixa }}</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td>{{  item.valor }}</td>&ndash;&gt;-->
<!--            &lt;!&ndash;                <td><v-btn @click="toggleExpand(item)"><v-icon>{{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>{{ expanded.includes(item) ? 'Esconder' : 'Produtos' }} </v-btn></td>&ndash;&gt;-->
<!--            &lt;!&ndash;              </tr>&ndash;&gt;-->
<!--            &lt;!&ndash;            </template>&ndash;&gt;-->

<!--            <template v-slot:expanded-item="{ }">-->
<!--              <td colspan="10" class="px-0 primary">-->
<!--                <v-simple-table class="table">-->
<!--                  <thead class="header">-->
<!--                  <tr class="cursor-normal">-->
<!--                    <th nowrap>Cód. produto</th>-->
<!--                    <th nowrap>Descrição produto</th>-->
<!--                    <th nowrap>Qtd. devolvida</th>-->
<!--                    <th nowrap>Valor UN. praticado</th>-->
<!--                    <th nowrap>Valor total praticado</th>-->
<!--                    <th nowrap>Valor UN. devolvida</th>-->
<!--                    <th nowrap>Valor total devolvida</th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody class="body text-capitalize">-->
<!--                  <tr v-for="(item, index) in dados2" :key="index" class="cursor-normal">-->
<!--                    <td nowrap>{{ item.idsubproduto }}</td>-->
<!--                    <td nowrap>{{ item.produto | formataTextoPedidoConcreto}}</td>-->
<!--                    <td nowrap>{{ item.qtdsaldodevolucao  }}</td>-->
<!--                    <td nowrap>{{ item.valorpraticado | formataDinheiro }}</td>-->
<!--                    <td nowrap>{{ item.valorsaldopraticado | formataDinheiro }}</td>-->
<!--                    <td nowrap>{{ item.valoratual | formataDinheiro }}</td>-->
<!--                    <td nowrap>{{ item.valorsaldoatual | formataDinheiro }}</td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                  <tfoot class="foot text-capitalize" v-if="dados2[0]">-->
<!--                  <tr class="cursor-normal">-->
<!--                    <td nowrap></td>-->
<!--                    <td nowrap></td>-->
<!--                    <td nowrap>{{ dados2.map(v => v.qtdsaldodevolucao).reduce((a,v) => a+v)  }}</td>-->
<!--                    <td nowrap>{{ dados2.map(v => v.valorpraticado).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                    <td nowrap>{{ dados2.map(v => v.valorsaldopraticado).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                    <td nowrap>{{ dados2.map(v => v.valoratual).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                    <td nowrap>{{ dados2.map(v => v.valorsaldoatual).reduce((a,v) => a+v) | formataDinheiro}}</td>-->
<!--                  </tr>-->
<!--                  </tfoot>-->
<!--                </v-simple-table>-->
<!--              </td>-->
<!--            </template>-->
<!--          </v-data-table>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </v-col>-->
<!--    <v-dialog v-model="dialogTebelas" width="900px" persistent>-->
<!--      <v-card dark class="primary text&#45;&#45;white white&#45;&#45;text">-->
<!--        <v-card-subtitle class="px-7 pt-6 pb-4">-->
<!--          <v-row class="pa-0">-->
<!--            Controle de tabelas relacionadas-->
<!--            <v-spacer />-->
<!--            <v-btn class="mt-n2" icon @click="dialogTebelas = !dialogTebelas">-->
<!--              <v-icon>mdi-close</v-icon>-->
<!--            </v-btn>-->
<!--          </v-row>-->
<!--        </v-card-subtitle>-->
<!--        <v-card-text class="pb-4 px-4">-->
<!--          <v-simple-table light>-->
<!--            <thead class="blue-grey">-->
<!--            <tr class="cursor-normal head-table-dialog">-->
<!--              <th nowrap>Tabela</th>-->
<!--              <th nowrap>Data de Movimento</th>-->
<!--              <th nowrap class="text-center">Status</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody class="text-capitalize">-->
<!--            <tr v-for="(item, index) in dados3" :key="index" class="cursor-normal">-->
<!--              <td nowrap>{{item.tabela}}</td>-->
<!--              <td nowrap>{{item.dtmovimento}}</td>-->
<!--              <td nowrap class="text-center"><v-chip small :color="item.status=='OK'?'success':'error'">{{item.status}}</v-chip></td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--            <tfoot class="font-weight-bold" v-if="dados3[0]">-->
<!--            <tr class="cursor-normal">-->
<!--              <td nowrap></td>-->
<!--              <td nowrap></td>-->
<!--            </tr>-->
<!--            </tfoot>-->
<!--          </v-simple-table>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </v-dialog>-->
    <v-dialog v-model="dialog" width="900px" persistent>
      <v-card dark class="primary text--white white--text">
        <v-card-subtitle class="px-7 pt-6 pb-4">
          <v-row class="pa-0">
            Detalhes
            <v-spacer />
            <v-btn class="mt-n2" icon @click="dialog = !dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-subtitle>
        <v-card-text class="pb-4 px-4">
          <v-simple-table light>
            <thead class="blue-grey">
              <tr class="cursor-normal head-table-dialog">
                <th nowrap>Cód. produto</th>
                <th nowrap>Descrição produto</th>
                <th nowrap>Qtd. baixada</th>
                <th nowrap>Valor unitário</th>
                <th nowrap>Valor total </th>
              </tr>
            </thead>
            <tbody class="text-capitalize">
              <tr v-for="(item, index) in dados2" :key="index" class="cursor-normal">
                <td nowrap>{{ item.idsubproduto }}</td>
                <td nowrap>{{ item.produto }}</td>
                <td nowrap>{{ item.qtdsaldodevolucao }}</td>
                <td nowrap>{{ item.valorpraticado | formataDinheiro }}</td>
                <td nowrap>{{ item.valorsaldopraticado | formataDinheiro }}</td>
              </tr>
            </tbody>
            <tfoot class="font-weight-bold" v-if="dados2[0]">
              <tr class="cursor-normal">
                <td nowrap></td>
                <td nowrap></td>
                <td nowrap>{{ dados2.map(v => v.qtdsaldodevolucao).reduce((a, v) => a + v) }}</td>
                <td nowrap>{{ dados2.map(v => v.valorpraticado).reduce((a, v) => a + v) | formataDinheiro }}</td>
                <td nowrap>{{ dados2.map(v => v.valorsaldopraticado).reduce((a, v) => a + v) | formataDinheiro }}</td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row class="my-1">
            <v-col class="py-0">Escolha uma das operação: </v-col>
            <v-col class="py-md-0 pr-md-1 py-sm-0"><v-btn small light @click="confirmaOperacao('C')"
                width="100%"><v-icon size="20" class="pr-1">mdi-cash-register</v-icon>Gerar crédito de
                devolução</v-btn></v-col>
            <v-col class="py-md-0 pl-md-2 py-sm-0"><v-btn small light @click="confirmaOperacao('P')"
                width="100%"><v-icon size="20" class="pr-1">mdi-truck-delivery</v-icon>Gerar novo pedido de
                venda</v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="450px" persistent>
      <v-card>
        <v-card-title>
          Confirma Processo
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4 pb-6" v-if="dados2[0]">
          Filial: {{ pedido.idfilial }} - {{ pedido.empresa }} <br />
          Nº documento: {{ pedido.idpedido }} <br />
          Data da venda: {{ pedido.databaixa }} <br />
          Tipo da venda: {{ pedido.modelo }} <br />
          Cliente: {{ pedido.idclifor }} - {{ pedido.cliente }}<br />
          <span v-if="creditoproduto == 'C'"> Valor do crédito: <v-chip x-small class="mr-2"> <strong>{{ dados2.map(v =>
            v.valorsaldopraticado).reduce((a, v) => a + v) | formataDinheiro }}</strong></v-chip> <br /> </span>
          <div v-else>
            Produtos: <span v-for="(item, index) in dados2.map(v => v.produto)" :key="index"> <v-chip x-small
                class="mr-2">
                <strong>{{ item | formataTextoCurto }}</strong></v-chip> </span>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-subtitle class="py-1 px-4 text-center">
          <strong>{{ msg }}</strong>
        </v-card-subtitle>
        <v-card-actions>
          <v-col class="pa-0 pr-2">
            <v-btn small class="error" width="100%" @click="confirmDialog = false, dialog = true">não<v-icon small
                class="pl-2">mdi-thumb-down</v-icon></v-btn>
          </v-col>
          <v-col class="pa-0 pl-2">
            <v-btn @click="lancarRemissao()" small class="primary" width="100%">sim<v-icon small
                class="pl-2">mdi-thumb-up</v-icon></v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog2" width="450px" persistent>
      <v-card>
        <v-card-title>
          Confirma Exclusão
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4 pb-4" v-if="dados2[0]">
          Filial: {{ pedido.idfilial }} - {{ pedido.empresa }} <br />
          Nº documento: {{ pedido.idpedido }} <br />
          Data do lançamento: {{ pedido.dtbaixa }} <br />
          Cliente: {{ pedido.idclifor }} - {{ pedido.cliente }}<br />
          Tipo de remissão: <v-chip small>{{ pedido.tipotransacao }}</v-chip>
        </v-card-text>

        <v-divider />
        <v-alert text color="error" icon="mdi-information" class="mt-0 pb-0">
          <p style="font-size: 0.8em">
            Caso exista a entrega do produto ou utilização do crédito, o processo de exclusão não será permitido!
          </p>
        </v-alert>
        <v-card-subtitle class="py-1 px-4 text-center">
          <strong>{{ msg }}</strong>
        </v-card-subtitle>
        <v-card-actions>
          <v-col class="pa-0 pr-2">
            <v-btn small class="error" width="100%" @click="confirmDialog2 = false">não<v-icon small
                class="pl-2">mdi-thumb-down</v-icon></v-btn>
          </v-col>
          <v-col class="pa-0 pl-2">
            <v-btn @click="desfazerRemissao()" small class="primary" width="100%">sim<v-icon small
                class="pl-2">mdi-thumb-up</v-icon></v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBloqueio" height="50vh" width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="mx-auto">{{ dialogDados.titulo }}</span>
        </v-card-title>
        <div class="text-center py-3">
          <v-icon color="primary" size="100">{{ dialogDados.icon }}</v-icon>
        </div>
        <v-card-text>
          <v-row class="text-h6 text-center">{{ dialogDados.msg }}</v-row>
          <v-row>
            <template>
              <v-text-field hint="Insira a observação para prosseguir" label="Observação" type="input"
                v-model="dadosLancamento.inputObs"></v-text-field>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn :disabled="dadosLancamento.inputObs == ''" color="primary" elevation="0" @click="lancarObs">{{
            dialogDados.txtBotao }}</v-btn>
          <v-btn color="primary" elevation="0"
            @click="dialogBloqueio = false, dadosLancamento.inputObs = ''">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import axios from "axios";

export default {
  name: "RemissaoLista",
  mixins: [mixinFilial],
  components: { InputDatePicker },
  data: () => ({
    selectAllChecked: false,
    intervalId: null,
    tab: null,
    snackbar: { status: false, timeout: 3000, color: 'success' },
    dialog: false,
    confirmDialog: false,
    confirmDialog2: false,
    dialogBloqueio: false,
    // dialogTebelas:false,
    dados: [],
    dados2: [],
    dados3: [],
    expanded: [],
    sheet: false,
    keydown: false,
    busca: {
      dtinicio: '2016-01-01',
      dtfim: `${new Date(new Date())
        .toLocaleDateString("pt-BR")
        .split("/")
        .reverse()
        .join("-")}`,
      idfiliais: [],
      flag: '',
      flagTipo: 'A',
      busca: null,
    },
    idpedido: 0,
    pedido: {},
    carregando: false,
    carregando2: false,
    msg: '',
    telaHistorico: false,
    creditoproduto: '',
    flagBloqueio: false,
    dialogDados: {
      titulo: '',
      msg: '',
      icon: '',
      txtBotao: ''
    },
    dadosLancamento: {
      idpedido: null,
      idfilial: null,
      inputObs: '',
      flagbloqueia: null
    }
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
            v.idfilial != 6 &&
            v.idfilial != 12 &&
            v.idfilial != 13 &&
            v.idfilial != 25 &&
            v.idfilial != 29 &&
            v.idfilial != 33 &&
            v.idfilial != 37 &&
            v.idfilial != 39 &&
            v.idfilial != 40
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    // Para Bloquear ou liberar remissão
    editaRemissao(item) {
      if (item.bloqueia == true) {
        this.dialogDados.titulo = 'Liberar remissão - ' + item.idpedido.toString();
        this.dialogDados.msg = 'Insira abaixo o motivo do desbloqueio da remissão. É obrigatório para realizar a operação.';
        this.dialogDados.txtBotao = 'Liberar';
        this.dialogDados.icon = 'mdi-file-lock-open-outline'
        this.dadosLancamento.flagbloqueia = false;
      } else {
        this.dialogDados.titulo = 'Bloquear remissão - ' + item.idpedido.toString();
        this.dialogDados.msg = 'Insira abaixo o motivo do bloqueio da remissão. É obrigatório para realizar a operação.';
        this.dialogDados.txtBotao = 'Bloquear';
        this.dialogDados.icon = 'mdi-file-lock-outline';
        this.dadosLancamento.flagbloqueia = true;
      }
      this.dadosLancamento.idpedido = item.idpedido;
      this.dadosLancamento.idfilial = item.idfilial;
      this.dialogBloqueio = true
    },
    lancarObs() {
      this.carregando = true;
      return axios
        .post(`${this.backendUrl}filial/remissao/observacao`, {
          idfilial: (this.dadosLancamento.idfilial).toString(),
          idpedido: this.dadosLancamento.idpedido,
          observacao: this.dadosLancamento.inputObs
        })
        .then(() => {
          this.carregando = false;
          this.bloqueiaLiberaRemissao();
        })
        .catch((e) => {
          this.carregando = false;
          this.snackbar.text = e.response.data.erro;
          this.snackbar.status = true;
          this.snackbar.color = 'error';
        });
    },
    bloqueiaLiberaRemissao() {
      this.carregando = true;
      return axios
        .post(`${this.backendUrl}filial/remissao/bloquear`, {
          idfilial: (this.dadosLancamento.idfilial).toString(),
          idpedido: this.dadosLancamento.idpedido,
          flagbloquear: this.dadosLancamento.flagbloqueia
        })
        .then(() => {
          this.carregando = false;
          this.snackbar.text = 'Operação concluída com sucesso!'
          this.snackbar.status = true;
          this.snackbar.color = 'success';
          this.dialogBloqueio = false;
          this.limpar();
          this.listar();
        })
        .catch((e) => {
          this.carregando = false;
          this.snackbar.text = e.response.data.erro;
          this.snackbar.status = true;
          this.snackbar.color = 'error';
          this.dialogBloqueio = false;
          this.listar();
          this.limpar();
        });
    },
    limpar() {
      this.dadosLancamento.idfilial = null;
      this.dadosLancamento.idpedido = null;
      this.dadosLancamento.inputObs = '';
      this.dadosLancamento.flagbloqueia = null;
    },
    toggleSelectAll(tipo) {
      if (tipo == 'CHECKBOX') {
        if (this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
      } else {
        if (!this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
        this.selectAllChecked = !this.selectAllChecked;
      }
    },
    desfazerRemissao() {
      this.carregando = true;
      this.confirmDialog2 = false;
      return axios
        .post(`${this.backendUrl}filial/remissao/desfazer`, {
          idfilial: (this.pedido.idfilial).toString(),
          idpedido: parseInt(this.pedido.idpedido),
          creditoproduto: this.creditoproduto//'C' OU 'P';
        })
        .then(() => {
          this.carregando = false;
          this.snackbar.text = 'Remissão excluída com sucesso!'
          this.snackbar.status = true;
          this.snackbar.color = 'success';
          this.listar();
        })
        .catch((e) => {
          this.carregando = false;
          this.snackbar.text = e.response.data.erro;
          this.snackbar.status = true;
          this.snackbar.color = 'error';
        });
    },
    lancarRemissao() {
      this.carregando = true;
      this.confirmDialog = false;
      return axios
        .post(`${this.backendUrl}filial/remissao/lancar`, {
          idfilial: (this.pedido.idfilial).toString(),
          idpedido: parseInt(this.pedido.idpedido),
          creditoproduto: this.creditoproduto//'C' OU 'P';
        })
        .then(() => {
          this.carregando = false;
          this.snackbar.text = 'Lançamento realizado com sucesso!'
          this.snackbar.status = true;
          this.snackbar.color = 'success';
          this.listar();
        })
        .catch((e) => {
          this.carregando = false;
          this.snackbar.text = e.response.data.erro;
          this.snackbar.status = true;
          this.snackbar.color = 'error';
        });
    },
    confirmaOperacao(tipo) {
      if (tipo == 'C') {
        this.creditoproduto = 'C';
        this.dialog = false;
        this.confirmDialog = true;
        this.msg = 'Confirma a criação de crédito?'
      } else if (tipo == 'P') {
        this.creditoproduto = 'P';
        this.dialog = false;
        this.confirmDialog = true;
        this.msg = 'Confirma a criação de um novo pedido de venda?'
      } else {
        if (tipo == 'Crédito de Devolução') {
          this.creditoproduto = 'C';
        } else {
          this.creditoproduto = 'P';
        }
        this.confirmDialog2 = true;
        this.msg = 'Confirma a exclusão do lançamento de remissão?'
      }
    },
    rowClass(item) {
      return this.expanded.includes(item) ? 'primary white--text' : '';
    },
    handleRowClick(item) {// toggleExpand(item)
      this.carregando2 = true;
      if (this.expanded.includes(item)) {
        // Se o item já estiver expandido, remove-o da lista de expandidos
        this.listarProdutos(item);
        this.expanded = this.expanded.filter(i => i !== item);
      } else {
        // Caso contrário, define a lista de expandidos para conter apenas o item atual
        this.listarProdutos(item);
        this.expanded = [item];
      }
    },
    listar() {
      clearInterval(this.intervalId);
      this.carregando = true;
      this.expanded = [];
      this.dados = [];
      setTimeout(() => {
        return axios
          .post(`${this.backendUrl}filial/remissao/listar`, {
            idfilial: this.busca.idfiliais.join(','),
            dtinicio: this.busca.dtinicio,
            dtfim: this.busca.dtfim,
            flag: this.busca.flag,
            flagtipo: this.tab == 'tab-1' ? 'A' : 'R',
            busca: this.busca.busca,
          })
          .then((res) => {
            this.dados = res.data.lista == null ? [] : res.data;
            this.carregando = false;
            this.carregandoSkeleton = false;
            this.telaHistorico = this.busca.flagTipo == 'A' ? false : true;
            // Configura o intervalo para chamar listar() a cada 10 minutos
            this.intervalId = setInterval(this.listar, 600000);
          })
          .catch(() => {
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
      }, 300);

    },
    listarProdutos(item) {
      this.pedido = item
      this.expanded = [];
      return axios
        .post(`${this.backendUrl}filial/remissao/produto/listar`, {
          idfilial: (item.idfilial).toString(),
          idpedido: parseInt(item.idpedido),
        })
        .then((res) => {
          this.dados2 = res.data == null ? [] : res.data;
          setTimeout(() => {
            this.carregando2 = false;
          }, 300);
        })
        .catch(() => {
          this.carregando2 = false;
        });
    },
    listarTabelas(item) {
      this.pedido = item
      this.expanded = [];
      this.carregando = true;
      return axios
        .post(`${this.backendUrl}filial/remissao/tabelas/listar`, {
          idfilial: (item.idfilial).toString(),
          idpedido: parseInt(item.idpedido),
        })
        .then((res) => {
          this.dados3 = res.data == null ? [] : res.data;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    async init() {
      this.busca.idfiliais.push(this.usuario.idfilial);
    },
  },
  created() {
    this.init();
  },
  mounted() {
    // Chama listar() imediatamente quando o componente é montado
    this.listar();
  },
  beforeDestroy() {
    // Limpa o intervalo quando o componente é destruído
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.table {
  margin: 0px 0px 10px 0px;
}

.header th {
  background-color: #CFD8DC !important;
  height: 25px !important;
}

.body td {
  height: 20px !important;
  font-size: larger;
}

.foot td {
  height: 20px !important;
  font-weight: 500 !important;
  border-top: #CFD8DC solid 1px !important;
  /*background-color: #CFD8DC !important;*/
}

::v-deep .v-data-table__wrapper table tbody tr:hover {
  cursor: pointer;
}

::v-deep .cursor-normal {
  cursor: default !important;
  /* ou 'auto' */
}

.head-table-dialog th {
  color: white !important;
}

.single-line-selection .v-select__selections {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.single-line-selection .v-select__selections span {
  display: inline-block;
  max-width: calc(100% - 60px);
  /* Ajuste conforme necessário */
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>