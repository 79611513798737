<template>
	<VueApexCharts type="bar" width="100%" :height="height" :options="chartOptions" :series="series" />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoBarraHorizontalDashboard",
	components: { VueApexCharts },
	props: {
		dataSeries: { type: Array, required: true },
		categories: { type: Array, required: true },
		maxValues: { type: Array, required: true }, // Limites para cada categoria
		height: { type: [Number, String], default: 430 },
		colors: { type: Array, default: () => ["#1ab7ea", "#0084ff"] },
		seriesLabels: { type: Array, default: () => ["Série 1", "Série 2"] },
	},
	data() {
		return {
			series: [],
			chartOptions: {
				chart: {
					type: "bar",
					toolbar: { show: false },
				},
				plotOptions: {
					bar: {
						horizontal: true,
						barHeight: "70%",
					},
				},
				dataLabels: {
					enabled: true,
					formatter: (value, { dataPointIndex }) =>
						`${(value * this.maxValues[dataPointIndex]).toFixed(2)}%`,
					offsetX: -6,
					style: {
						fontSize: "12px",
						colors: ["#fff"],
					},
				},
				stroke: {
					show: false,
					width: 1,
					colors: ["#fff"],
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: (value, { dataPointIndex }) =>
							`${(value * this.maxValues[dataPointIndex]).toFixed(2)}%`,
					},
				},
				xaxis: {
					categories: [],
					decimalsInFloat: 1,
					min: 0,
					max: 1, // Normalizado entre 0 e 1
					labels:{
						show:false
					}
				},

				colors: [],
			},
		};
	},
	created() {
		// Normalize os dados e adicione os nomes às séries
		this.series = this.dataSeries.map((serie, index) => ({
			name: this.seriesLabels[index], // Nome da série
			data: serie.data.map((value, i) => value / this.maxValues[i]), // Valores normalizados
		}));

		this.chartOptions.xaxis.categories = this.categories; // Categorias do eixo x
		this.chartOptions.colors = this.colors; // Cores das séries
	},
};
</script>