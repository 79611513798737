<template>
	<v-row>
		<DialogDashboards :dialog="dialog" />
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-form>
						<v-row class="mb-n10">
							<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtinicio"
									label="Data inicial"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col class="d-none d-md-block px-1">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtfim"
									label="Data final"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
								<v-sheet class="text-center" height="100%">
									<v-row class="pa-3">
										<v-col cols="6">
											<InputDatePicker
												:disabled="carregando"
												v-model="busca.dtinicio"
												label="Data inicial"
												:outlined="true"
												:dense="true"
											/>
										</v-col>
										<v-col cols="6">
											<InputDatePicker
												:disabled="carregando"
												v-model="busca.dtfim"
												label="Data final"
												:outlined="true"
												:dense="true"
											/>
										</v-col>
										<v-col cols="6">
											<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
										</v-col>
										<v-col cols="6">
											<v-btn text class="mt-n3" color="primary" @click="get(), sheet = !sheet">Filtrar</v-btn>
										</v-col>
									</v-row>
								</v-sheet>
							</v-bottom-sheet>
							<v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
								<v-autocomplete
									class="d-inline-flex d-md-none"
									append-outer-icon="mdi-menu"
									@click:append-outer="sheet = !sheet"
									:items="filiais.lista"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfiliais"
									multiple
								>
									<template v-slot:selection="{ item, index }">
										<template v-if="index === 0">{{ item.filial }}</template>
										<span
											v-if="index === 1"
											class="grey--text text-caption ml-1"
										>(+{{ busca.idfiliais.length - 1 }})</span>
									</template>
								</v-autocomplete>
								<v-autocomplete
									class="d-none d-md-block"
									:items="filiais.lista"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfiliais"
									multiple
								>
									<template v-slot:selection="{ item, index }">
										<template v-if="index === 0">{{ item.filial }}</template>
										<span
											v-if="index === 1"
											class="grey--text text-caption ml-1"
										>(+{{ busca.idfiliais.length - 1 }})</span>
									</template>
								</v-autocomplete>
								<v-btn
									:disabled="carregando"
									class="ml-2 mt-1"
									color="primary"
									elevation="0"
									fab
									x-small
									@click="get()"
								>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-row class="px-2 pt-2">
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Contas à pagar">
						<template v-if="dados.contasapagar">{{ dados.contasapagar | formataDinheiro }}</template>
						<template v-else>{{ 0 | formataDinheiro }}</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Provisão de remessas">
						<template v-if="dados.contasareceber">
              {{ ((dados.valdinheiro.map(v => v.valor).reduce((a,v) => a + v)) +
                   dados.valbanco.map(v => v.valor).reduce((a,v) => a + v) +
                   (dados?.valcheque ? dados.valcheque.map(v => v.valor).reduce((a,v) => a + v) : 0) +
                   dados.valcontasareceber.map(v => v.valor).reduce((a,v) => a + v)) | formataDinheiro }}</template>
						<template v-else>{{ 0 | formataDinheiro }}</template>
<!--            texto="Saldo em dinheiro no fechamento de caixa do dia anterior + saldo em banco no fechamento de caixa do dia anterior + saldo em cheque no período + contas a receber no período"-->
						<MenuInfo
							texto="Saldo em dinheiro do dia + saldo em banco do dia + saldo em cheque no período + contas a receber no período"
						/>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Saldo no período">
						<template
							v-if="dados.contasapagar && dados.contasareceber"
						>{{ ((dados.valdinheiro.map(v => v.valor).reduce((a,v) => a + v)) +
                dados.valbanco.map(v => v.valor).reduce((a,v) => a + v) +
                dados.valcheque.map(v => v.valor).reduce((a,v) => a + v) +
                dados.valcontasareceber.map(v => v.valor).reduce((a,v) => a + v)) - dados.contasapagar | formataDinheiro }}</template>
						<template v-else>{{ 0 | formataDinheiro }}</template>
						<MenuInfo texto="Provisão de remessas - contas à pagar" />
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Contas à receber vencidas">
						<template
							v-if="dados.valcontasarecebervencida"
						>{{ dados.valcontasarecebervencida.map(v => v.valor).reduce((a,v) => a + v) | formataDinheiro }}</template>
						<template v-else>{{ 0 | formataDinheiro }}</template>
						<MenuInfo texto="Valores atualizados a cada 5 minutos." />
						<v-btn
							fab
							text
							x-small
							class="float-right"
							@click="abrirDialog(
                                dados.valcontasarecebervencida, 
								{
									idempresa: {nome: 'ID Filial', tipo: 'texto'},
									empresa: {nome: 'Filial', tipo: 'texto'},
									valor: {nome: 'Valor', tipo: 'dinheiro'},
								},
                                'Saldo de contas à receber vencidas por filial'
                            )"
						>
							<v-icon>mdi-eye-plus-outline</v-icon>
						</v-btn>
					</CardDashboard>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12">
			<v-row class="px-2 pb-2">
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Saldo em dinheiro">
						<template
							v-if="dados.valdinheiro"
						>{{ dados.valdinheiro.map(v => v.valor).reduce((a,v) => a + v) | formataDinheiro }}</template>
						<template v-else>{{ 0 | formataDinheiro }}</template>
						<MenuInfo texto="Saldo de dinheiro em caixa com base na data final" />
						<v-btn
							fab
							text
							x-small
							class="float-right"
							@click="abrirDialog(
                                dados.valdinheiro, 
								{
									idempresa: {nome: 'ID Filial', tipo: 'texto'},
									empresa: {nome: 'Filial', tipo: 'texto'},
									valor: {nome: 'Valor', tipo: 'dinheiro'},
								},
                                'Saldo em dinheiro por filial'
                            )"
						>
							<v-icon>mdi-eye-plus-outline</v-icon>
						</v-btn>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Saldo em banco">
						<template
							v-if="dados.valbanco"
						>{{ dados.valbanco.map(v => v.valor).reduce((a,v) => a + v) | formataDinheiro }}</template>
						<template v-else>{{ 0 | formataDinheiro }}</template>
						<MenuInfo texto="Saldo em conta bancária com base na data final" />
						<v-btn
							fab
							text
							x-small
							class="float-right"
							@click="abrirDialog(
                                dados.valbanco, 
								{
									idempresa: {nome: 'ID Filial', tipo: 'texto'},
									empresa: {nome: 'Filial', tipo: 'texto'},
									valor: {nome: 'Valor', tipo: 'dinheiro'},
								},
                                'Saldo bancário por filial'
                            )"
						>
							<v-icon>mdi-eye-plus-outline</v-icon>
						</v-btn>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Saldo em cheques">
						<template
							v-if="dados.valcheque"
						>{{ dados.valcheque.map(v => v.valor).reduce((a,v) => a + v) | formataDinheiro }}</template>
						<template v-else>{{ 0 | formataDinheiro }}</template>
						<v-btn
							fab
							text
							x-small
							class="float-right"
							@click="abrirDialog(
                                dados.valcheque, 
								{
									idempresa: {nome: 'ID Filial', tipo: 'texto'},
									empresa: {nome: 'Filial', tipo: 'texto'},
									valor: {nome: 'Valor', tipo: 'dinheiro'},
									vencimento: {nome: 'Valor', tipo: 'texto'},
									status: {nome: 'Valor', tipo: 'texto'},
								},
                                'Saldo de cheques por filial'
                            )"
						>
							<v-icon>mdi-eye-plus-outline</v-icon>
						</v-btn>
					</CardDashboard>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" md="6">
			<v-card color="primary">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="white--text">Contas à pagar por filial</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<v-simple-table>
						<thead>
							<tr>
								<th></th>
								<th>Filial</th>
								<th>Valor</th>
							</tr>
						</thead>
						<tbody v-if="dados.valcontasapagar">
							<tr v-for="(u,i) in dados.valcontasapagar" :key="i">
								<td>
									<v-btn
										:disabled="carregando"
										color="primary"
										elevation="0"
										fab
										x-small
										@click="listarFornecedores(u.idempresa)"
									>
										<v-icon>mdi-magnify-plus-outline</v-icon>
									</v-btn>
								</td>
								<td v-if="u.idempresa == 29">Depósito Reserva (Loja 29)</td>
								<td v-else>{{u.empresa}}</td>
								<td>{{u.valor | formataDinheiro}}</td>
							</tr>
						</tbody>
						<tfoot v-if="dados.valcontasapagar">
							<tr class="font-weight-bold">
								<td></td>
								<td>Total</td>
								<td>{{dados.valcontasapagar.map(v => v.valor).reduce((a,v) => a + v) | formataDinheiro}}</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="6">
			<v-card color="primary">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="white--text">Contas à receber por filial</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<v-simple-table>
						<thead>
							<tr>
								<th></th>
								<th>Filial</th>
								<th>Valor</th>
								<th>Saldo</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(u,i) in dados.valcontasareceber" :key="i">
								<td>
									<v-btn
										:disabled="carregando"
										color="primary"
										elevation="0"
										fab
										x-small
										@click="listarClientes(u.idempresa)"
									>
										<v-icon>mdi-magnify-plus-outline</v-icon>
									</v-btn>
								</td>
								<td v-if="u.idempresa == 29">Depósito Reserva (Loja 29)</td>
								<td v-else>{{u.empresa}}</td>
								<td>{{u.valor | formataDinheiro}}</td>
								<td>{{u.saldo | formataDinheiro}}</td>
							</tr>
						</tbody>
						<tfoot v-if="dados.valcontasareceber">
							<tr class="font-weight-bold">
								<td></td>
								<td>Total</td>
								<td>{{dados.valcontasareceber.map(v => v.valor).reduce((a,v) => a + v) | formataDinheiro}}</td>
								<td>{{dados.valcontasareceber.map(v => v.saldo).reduce((a,v) => a + v) | formataDinheiro}}</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card color="primary">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="white--text">
					Análise histórico financeiro
					<MenuInfo
						texto="Comparativo entre despesas pagas da filial, valor de remessa enviado e valor de provisão de remessa"
					/>
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<v-simple-table>
						<thead>
							<tr>
								<th>Filial</th>
								<th>Despesas Pagas</th>
								<th>Remessa enviada</th>
								<th>Provisão de remessa</th>
								<th>Diferença enviado x provisão</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(u,i) in dados.valhistorico" :key="i">
								<td v-if="u.idempresa == 29">Depósito Reserva (Loja 29)</td>
								<td v-else>{{u.empresa}}</td>
								<td>{{u.despesaspagas | formataDinheiro}}</td>
								<td>{{u.remessaenviada | formataDinheiro}}</td>
								<td>{{u.projecao | formataDinheiro}}</td>
								<td>{{(u.remessaenviada - u.projecao) | formataDinheiro}}</td>
							</tr>
						</tbody>
						<tfoot v-if="dados.valhistorico">
							<tr class="font-weight-bold">
								<td>Total</td>
								<td>{{dados.valhistorico.map(v => v.despesaspagas).reduce((a,v) => a + v) | formataDinheiro}}</td>
								<td>{{dados.valhistorico.map(v => v.remessaenviada).reduce((a,v) => a + v) | formataDinheiro}}</td>
								<td>{{dados.valhistorico.map(v => v.projecao).reduce((a,v) => a + v) | formataDinheiro}}</td>
								<td>{{(dados.valhistorico.map(v => v.remessaenviada).reduce((a,v) => a + v) - dados.valhistorico.map(v => v.projecao).reduce((a,v) => a + v)) | formataDinheiro}}</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker";
import mixinFilial from "../../util/mixinFilial";
import CardDashboard from "../Widgets/CardDashboard";
import DialogDashboards from "../Widgets/DialogDashboards";
import MenuInfo from "../Widgets/MenuInfo";

export default {
	name: "DashboardRemessas",
	mixins: [mixinFilial],
	components: {
		InputDatePicker,
		CardDashboard,
		DialogDashboards,
		MenuInfo,
	},
	data: () => ({
		sheet: false,
		dialog: { status: false, dados: [], header: {} },
		carregando: false,
		dados: {},
		filiais: {},
		busca: {
			dtinicio: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate()
				).getDate() < 10
					? "0"
					: "") + new Date().getDate()
			}`,

			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate()
				).getDate() < 10
					? "0"
					: "") + new Date().getDate()
			}`,
			idfiliais: 0,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
		get() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			}
			this.dados = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/remessa`, {
					dtinicio: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					filial: idfiliais || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.dados.valdinheiro = this.mesclaCD(this.dados.valdinheiro);
					this.dados.valbanco = this.mesclaCD(this.dados.valbanco);
					this.dados.valcontasapagar = this.dados.valcontasapagar.filter((v) => v.valor > 0);
					this.carregando = false;
				})
				.catch((err) => {
					console.error(err);
					this.carregando = false;
				});
		},
		listarEmpresas() {
			this.busca.idfiliais = [];
			return axios
				.post(`${this.backendUrl}filial/listar`, {
					limit: 9999,
					offset: 0,
					idregional: this.busca.idregional || null,
					busca: null,
				})
				.then((res) => {
					this.filiais = res.data;
					this.filiais.lista = this.filiais.lista.filter((v) => {
						return (
							v.idfilial !== 22 &&
							v.idfilial !== 32 &&
							v.idfilial !== 34
						);
					});
				});
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
		listarFornecedores(id) {
			if (!id) return;
			return axios
				.post(`${this.backendUrl}dashboard/remessa/despesas`, {
					dtinicio: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					filial: `${id}`,
				})
				.then((res) => {
					let total = {};
					total.fornecedor = "TOTAL";
					total.idfornecedor = null;
					total.status = null;
					total.vencimento = null;
					total.valor = res.data
						.map((v) => v.valor)
						.reduce((a, v) => a + v);
					let dados = res.data;
					dados.push(total);
					this.abrirDialog(
						dados,
						{
							idfornecedor: { nome: "ID", tipo: "texto" },
							fornecedor: { nome: "Fornecedor", tipo: "texto" },
							valor: { nome: "Valor", tipo: "dinheiro" },
							vencimento: { nome: "Vencimento", tipo: "texto" },
							status: { nome: "Status", tipo: "texto" },
						},
						"Contas à pagar por fornecedor"
					);
				});
		},
		listarClientes(id) {
			if (!id) return;
			return axios
				.post(`${this.backendUrl}dashboard/remessa/receitas`, {
					dtinicio: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					filial: `${id}`,
				})
				.then((res) => {
					let total = {};
					total.cliente = "TOTAL";
					total.idcliente = null;
					total.idtitulo = null;
					total.vencimento = null;
					total.saldo = res.data
						.map((v) => v.saldo)
						.reduce((a, v) => a + v);
					total.valor = res.data
						.map((v) => v.valor)
						.reduce((a, v) => a + v);
					let dados = res.data;
					dados.push(total);
					this.abrirDialog(
						dados,
						{
							idcliente: { nome: "ID", tipo: "texto" },
							cliente: { nome: "Cliente", tipo: "texto" },
							valor: { nome: "Valor", tipo: "dinheiro" },
							saldo: { nome: "Saldo", tipo: "dinheiro" },
							vencimento: { nome: "Vencimento", tipo: "texto" },
						},
						"Contas à receber por cliente"
					);
				});
		},
		mesclaCD(array) {
			let todas = array.filter(
				(v) =>
					v.idempresa != 7 &&
					v.idempresa != 12 &&
					v.idempresa != 23 &&
					v.idempresa != 25 &&
					v.idempresa != 29 &&
					v.idempresa != 31 &&
					v.idempresa != 36 &&
					v.idempresa != 37
			);
			let apucarana = {
				idempresa: 7,
				empresa: "Apucarana",
				valor: array
					.filter((v) => v.idempresa == 7 || v.idempresa == 12)
					.map((v) => v.valor)
					.reduce((a, v) => a + v),
			};
			let saoMateus = {
				idempresa: 23,
				empresa: "São Mateus",
				valor: array
					.filter((v) => v.idempresa == 23 || v.idempresa == 25)
					.map((v) => v.valor)
					.reduce((a, v) => a + v),
			};
			let reserva = {
				idempresa: 31,
				empresa: "Reserva",
				valor: array
					.filter((v) => v.idempresa == 29 || v.idempresa == 31)
					.map((v) => v.valor)
					.reduce((a, v) => a + v),
			};
			let maringa = {
				idempresa: 36,
				empresa: "Maringá",
				valor: array
					.filter((v) => v.idempresa == 36 || v.idempresa == 37)
					.map((v) => v.valor)
					.reduce((a, v) => a + v),
			};
			todas.push(apucarana);
			todas.push(saoMateus);
			todas.push(reserva);
			todas.push(maringa);
			todas.sort((a, b) => a.idempresa - b.idempresa);
			return todas;
		},
		async init() {
			await this.listarEmpresas();
			await this.get();
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>