import { render, staticRenderFns } from "./DashboardComparativoDRE.vue?vue&type=template&id=04573c46&scoped=true"
import script from "./DashboardComparativoDRE.vue?vue&type=script&lang=js"
export * from "./DashboardComparativoDRE.vue?vue&type=script&lang=js"
import style0 from "./DashboardComparativoDRE.vue?vue&type=style&index=0&id=04573c46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04573c46",
  null
  
)

export default component.exports