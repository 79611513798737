<template>
  <v-row>
    <v-dialog v-model="dialogErro.status" persistent>
      <CardDialog :dialog="dialogErro" />
    </v-dialog>

    <v-dialog v-model="dialogObs" scrollable>
      <v-card min-width="500">
        <v-card-title class="pa-4">ADICIONAR OBSERVAÇÃO ({{ cliente.cliente }})</v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <v-textarea v-model="cliente.observacao" label="Observação" outlined class="mb-n4"
                      counter="2000"></v-textarea>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn :loading="carregando" text color="primary" elevation="0"
                 @click="dialogObs = false">Fechar</v-btn>
          <v-btn :loading="carregando" color="primary" elevation="0" @click="adcObs()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-card class="my-2">
        <v-card-title>
          <v-row no-gutters>
            <v-col class="pr-2">
              <v-select :items="datas" item-text="name" item-value="value" label="Período" outlined dense
                        v-model="busca.data" hide-details></v-select>
            </v-col>

            <v-col class="pr-2">
              <v-select :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial"
                        outlined dense v-model="busca.idfiliais" hide-details multiple
                        class="single-line-selection">
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.filial }}</span>
                  <span v-if="index === 1" class="grey--text text-caption">
										(+{{ busca.idfiliais.length - 1 }})
									</span>
                </template>

                <template #prepend-item>
                  <v-list-item class="pr-0" dense>
                    <v-list-item-action>
                      <v-checkbox v-model="selectAllChecked"
                                  @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content @click="toggleSelectAll('LABEL')">
                      <!-- Use uma tag label para vincular ao checkbox -->
                      <label class="text-subtitle-2">Todas</label>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col cols="auto" class="pl-2">
              <v-btn :disabled="carregando" class="mt-1" color="primary" elevation="0" fab rounded x-small
                     @click="listar(0)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
      </v-card>

      <v-card class="primary my-4">
        <v-card-subtitle v-if="dados.totalcredu > 0" class="white--text font-weight-black text-center">
          <v-icon class="mr-2" color="white" size="30">mdi-cash</v-icon>
          <span>SALDO TOTAL DE CREDU BUSCADO: {{ dados.totalcredu | formataDinheiro }} </span>
        </v-card-subtitle>
        <v-card-subtitle v-else-if="dados.totalcredu == null" class="white--text font-weight-black text-center">
          <v-icon class="mr-2" color="white" size="30">mdi-cash</v-icon>
          <span>SALDO TOTAL DE CREDU BUSCADO: {{ dados.totalcredu | formataDinheiro }} </span>
        </v-card-subtitle>
        <v-card-subtitle v-else class="white--text font-weight-black text-center">
          <span>CARREGANDO SALDO TOTAL DE CREDU BUSCADO...</span>
        </v-card-subtitle>
        <v-divider class="white" />

        <v-card-text class="pa-3">
          <v-skeleton-loader v-if="carregando" type="table-tbody" />

          <v-data-table v-else :headers="[
						{ text: 'Cód. Filial', value: 'idempresa', class: 'text-no-wrap' },
						{ text: 'Cód Cliente', value: 'codigo', class: 'text-no-wrap' },
						{ text: 'Cliente', value: 'cliente', class: 'text-no-wrap' },
						{ text: 'Valor', value: 'saldotitulo', class: 'text-no-wrap' },
						{ text: 'Tipo', value: 'tipo', class: 'text-no-wrap' },
						{ text: 'Risco', value: 'risco', class: 'text-no-wrap ', sortable: false },
						{ text: 'Com Obs.', value: 'observacaoAdicionada', class: 'text-no-wrap', align: 'center', sortable: false },
						{ text: 'Observação', value: 'botaoEditaObs', class: 'text-no-wrap', align: 'center', sortable: false },]"
                        :items="dados.lista" :expanded.sync="expanded" expand-icon="mdi-menu-down"
                        item-key="compositeKey" class="elevation-0" :items-per-page="50" @click:row="handleRowClick"
                        single-expand :item-class="rowClass" mobile-breakpoint="0" hide-default-footer>


            <template v-slot:header.risco="{ header }">
              <v-tooltip right color="primary" content-class='custom-tooltip'>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>Risco de entrar no Credu</span>
              </v-tooltip>
            </template>

            <template v-slot:header.idempresa="{ header }">
              <span class="pr-0">{{ header.text }}</span>
            </template>

            <template v-slot:header.codigo="{ header }">
              <span class="pr-0">{{ header.text }}</span>
            </template>

            <template v-slot:item.idempresa="{ item }">
              <span class="text-truncate pr-0">{{ item.idempresa }}</span>
            </template>

            <template v-slot:item.codigo="{ item }">
              <span class="pr-0">{{ item.codigo }}</span>
            </template>

            <template v-slot:item.cliente="{ item }">
              <span>{{ item.cliente }}</span>
            </template>

            <template v-slot:item.saldotitulo="{ item }">
              {{ item.saldotitulo | formataDinheiro }}
            </template>

            <template v-slot:header.tipo="{ header }">
              <v-tooltip right color="primary" content-class='custom-tooltip'>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>Tipo de Título</span>
              </v-tooltip>
            </template>

            <template v-slot:item.risco="{ item }">
              <v-chip small :class="estilizaRisco(item.risco)" class="white--text font-weight-bold">
                {{ item.risco }}</v-chip>
            </template>

            <template v-slot:header.observacaoAdicionada="{ header }">
              <v-tooltip right color="primary" content-class='custom-tooltip'>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>Informa se há observação adicionada ao título ou não.</span>
              </v-tooltip>
            </template>

            <template v-slot:item.observacaoAdicionada="{ item }">
              <span class="text-truncate">{{ verificaOBS(item) }}</span>
            </template>

            <template v-slot:item.botaoEditaObs="{ item }">
              <v-icon class="ml-2" @click="cliente = item, dialogObs = true" color="red darken-3"
                      size="20">mdi-note-plus-outline</v-icon>
            </template>

            <template template v-slot:expanded-item="{}">
              <td colspan="10" class="px-0 primary">
                <v-simple-table class="table">
                  <thead class="header">
                  <tr class="cursor-normal">
                    <th nowrap>Titulo</th>
                    <th nowrap>Digito Titulo</th>
                    <th nowrap>Saldo Titulo</th>
                    <th nowrap>Juros Mora</th>
                    <th nowrap>Valor Atualizado</th>
                    <th nowrap>Tipo</th>
                  </tr>
                  </thead>
                  <tbody class="body text-capitalize">
                  <tr v-if="carregando2">
                    <td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td>
                  </tr>
                  <tr v-for="(item, index) in dadosCliente.lista" :key="index"
                      class="cursor-normal" v-else>
                    <td nowrap>{{ item.titulo }}</td>
                    <td nowrap>{{ item.digitotitulo }}</td>
                    <td nowrap>{{ item.saldotitulo | formataDinheiro }}</td>
                    <td nowrap>{{ item.jurosmora | formataDinheiro }}</td>
                    <td nowrap>{{ item.valoratualizado | formataDinheiro }}</td>
                    <td nowrap>{{ item.tipo }}</td>
                  </tr>
                  </tbody>
                  <tfoot class="foot text-capitalize" v-if="dadosCliente.lista[0] && !carregando2">
                  <tr class="cursor-normal">
                    <td nowrap></td>
                    <td nowrap class="bold-text">Totais:</td>
                    <td nowrap class="bold-text">{{ dadosCliente.lista.map(v =>
                        v.saldotitulo).reduce((a, v) => a + v, 0) |
                        formataDinheiro }}</td>
                    <td nowrap class="bold-text">{{ dadosCliente.lista.map(v =>
                        v.jurosmora).reduce((a, v) => a + v, 0) |
                        formataDinheiro }}</td>
                    <td nowrap class="bold-text">{{ dadosCliente.lista.map(v =>
                        v.valoratualizado).reduce((a, v) =>
                        a + v, 0) | formataDinheiro }}</td>
                    <td nowrap></td>
                  </tr>
                  </tfoot>
                </v-simple-table>
              </td>
            </template>

          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <Paginacao :class="dados.total > 20 ? 'my-2 pb-2' : ''" :total="dados.total" @atualizar="listar"
                   :carregando="carregando" :pg="pgSelecionada" />

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import Paginacao from "../Widgets/Paginacao.vue";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
  components: { Paginacao, CardDialog },
  name: "CreduLista",
  mixins: [mixinFilial],
  data: () => ({
    dialog: false,
    dialogObs: false,
    dialogErro: {
      status: false,
      title: "",
      msg: "",
      icon: "",
      iconColor: "",
    },
    busca: {
      data: `${new Date(new Date())
          .toLocaleDateString("pt-BR")
          .split("/")
          .reverse()
          .join("-")}`,
      idfiliais: [],
    },
    datas: [],
    selectAllChecked: false,
    carregando: true,
    carregando2: false,
    carregandoSkeleton: true,
    corIcon: false,
    dados: { lista: [], total: 0, totalcredu: 0 },
    dadosCliente: { lista: [] },
    dadosTodosCliente: { lista: [] },
    v: false,
    expanded: [],
    cliente: {},
    pgSelecionada: 0,
    todosDadosCliente: [],
    anoAtual: new Date(),

  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
              v.idfilial != 6 &&
              v.idfilial != 12 &&
              v.idfilial != 13 &&
              v.idfilial != 25 &&
              v.idfilial != 29 &&
              v.idfilial != 33 &&
              v.idfilial != 37 &&
              v.idfilial != 39 &&
              v.idfilial != 40 &&
              v.idfilial != 42

          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    listar(pg) {
      this.pgSelecionada = pg;
      this.carregando = true;
      this.dados.lista = [];
      this.dados.totalcredu = 0;
      this.dados.total = 0;
      this.expanded = [];
      return axios
          .post(`${this.backendUrl}credu/listar`, {
            data: this.busca.data,
            idempresa: this.busca.idfiliais.join(','),
            limit: this.pgLimit,
            offset: this.pgLimit * pg,
          })
          .then((res) => {
            this.dados.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
              const compositeKey = `${v.codigo}-${v.tipo}`;
              return {
                ...v,
                compositeKey: compositeKey,
              };
            });
            this.dados.total = res.data.total;
            this.dados.totalcredu = res.data.totalcredu;
            this.carregando = false;
            this.carregandoSkeleton = false;
          })
          .catch(() => {
            this.dados = { lista: [], total: 0 };
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
    },
    verificaOBS(item) {
      if (item.observacao && item.observacao.trim() !== "") {
        return "Sim"
      } else {
        return "Não"
      }
    },
    get(id, chavecomposta) {
      return axios
          .post(`${this.backendUrl}credu/cliente/listar`, {
            idclifor: id,
            data: this.busca.data,
            idempresa: this.busca.idfilial,
          })
          .then((res) => {
            this.todosDadosCliente.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
              const compositeKey = `${v.codigo}-${v.tipo}`;
              return {
                ...v,
                compositeKey: compositeKey,
              };
            });
            this.dadosCliente.lista = this.todosDadosCliente.lista.filter(cliente => cliente.compositeKey === chavecomposta);
            this.carregando = false;
            this.carregando2 = false;
            this.carregandoSkeleton = false;
          })
          .catch(() => {
            this.dadosCliente = { lista: [] };
            this.carregando = false;
            this.carregando2 = false;
            this.carregandoSkeleton = false;
          });
    },
    periodos() {
      let ano = this.anoAtual.getFullYear();
      return axios
          .post(`${this.backendUrl}credu/data/listar`, {})
          .then((res) => {
            this.datas = res.data.lista
                .filter(v => v.anoinicio >= ano) // Evita valores undefined
                .map((v) => ({
                  ...v,
                  name: `${v.descricao}/${v.anotermino}`,
                  value: v.datatermino.split("T")[0],
                }));
          });
    },
    adcObs() {
      return axios
          .post(`${this.backendUrl}credu/pre/cadastro/inserir`, {
            idempresa: this.cliente.idempresa,
            idclifor: this.cliente.codigo,
            observacao: this.cliente.observacao,
          })
          .then((res) => {
            if (res.data) {
              this.cliente = {};
              this.dialogObs = false;
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg =
                  "Categoria adicionada com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
            } else {
              this.dialogErro.title = "Erro";
              if (res.data.erro) {
                this.dialogErro.msg =
                    "Não foi possível adicionar a observação!";
              } else {
                this.dialogErro.msg = res.data.erro;
              }
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
            }
          })
          .catch(() => {
            this.dialogErro.title = "Erro";
            this.dialogErro.msg =
                "Não foi possível adicionar a observação!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
          });
    },
    rowClass(item) {
      return this.expanded.includes(item) ? 'primary white--text' : '';
    },
    handleRowClick(item) {// toggleExpand(item)
      this.carregando2 = true;
      this.corIcon = true;
      if (this.expanded.includes(item)) {
        // Se o item já estiver expandido, remove-o da lista de expandidos
        this.get(item.codigo, item.compositeKey);
        this.expanded = this.expanded.filter(i => i !== item);
      } else {
        // Caso contrário, define a lista de expandidos para conter apenas o item atual
        this.get(item.codigo, item.compositeKey);
        this.expanded = [item];
      }
    },
    toggleSelectAll(tipo) {
      if (tipo == 'CHECKBOX') {
        if (this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
      } else {
        if (!this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
        this.selectAllChecked = !this.selectAllChecked;
      }
    },
    estilizaRisco(item) {
      if (item == 'BAIXO') {
        return 'warning'
      } else if (item == 'MEDIO') {
        return 'deep-orange'
      } else if (item == 'ALTO') {
        return 'red darken-3'
      }
    },
    verificaData(dataIni, dataFim) {
      const hoje = new Date();
      const inicio = new Date(dataIni);
      const fim = new Date(dataFim);

      // Verifica se hoje está entre as datas
      return hoje >= inicio && hoje <= fim;
    },
    // Método para filtrar o período atual
    predefineData() {
      if (this.datas.length === 0) return; // Se não houver dados, não tente fazer a busca

      const periodoAtual = this.datas.find(data =>
          this.verificaData(data.datainicio, data.datatermino)
      );

      if (periodoAtual) {
        this.busca.data = periodoAtual.datatermino.split("T")[0];
      }
    },
    async init() {
      this.busca.idfiliais.push(this.usuario.idfilial);
      //this.busca.idfilial = this.usuario.idfilial;
      await this.periodos();
      await this.predefineData();
      await this.listar(0);
    },

  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.table {
  margin: 0px 0px 10px 0px;
}

.header th {
  background-color: #CFD8DC !important;
  height: 25px !important;
}

.body td {
  height: 20px !important;
  font-size: larger;
}

.bold-text {
  font-weight: bold;
}

.foot td {
  height: 20px !important;
}
</style>

