var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v(" Informações "),_c('v-spacer'),_c('ClienteAlterar',{attrs:{"idcliente":_vm.idcliente},on:{"atualizar":_vm.get}})],1),_c('v-divider'),_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-text',[_c('div',{staticClass:"my-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-identifier")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("ID:")]),_vm._v(" "+_vm._s(_vm.dados.idcliente)+" ")],1),_c('div',{staticClass:"my-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-outline")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nome:")]),_vm._v(" "+_vm._s(_vm.dados.nome)+" ")],1),(_vm.dados.endereco)?_c('div',{staticClass:"my-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-map-marker")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Endereço:")]),_vm._v(" "+_vm._s(_vm.dados.endereco.endereco)+", Nº"+_vm._s(_vm.dados.endereco.numero)+" ")],1):_vm._e(),(_vm.dados.endereco)?_c('div',{staticClass:"my-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-city")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Cidade:")]),_vm._v(" "+_vm._s(_vm.dados.endereco.cidade)+"-"+_vm._s(_vm.dados.endereco.uf)+" ")],1):_vm._e()])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ClienteListaLocaisEntrega',{attrs:{"dados":_vm.dados},on:{"atualizar":_vm.get}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ClienteListaUsuario',{attrs:{"pgLimitGet":3,"idcliente":_vm.idcliente}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',[_c('Table',{attrs:{"pgLimitGet":5,"backendDirectory":"pedido/listar","busca":{
                            idcliente: parseInt(_vm.idcliente),
                            busca: _vm.pedidos.busca || null
                        },"filter":true,"headers":{
                            idpedido: {nome: 'ID', tipo: 'link', link: '../../venda/pedido/get/'},
                            filial: {nome: 'Filial', tipo: 'texto'},
                            valortotal: {nome: 'Valor', tipo: 'dinheiro'},
                            dtpedido: {nome: 'Data do pedido', tipo: 'texto'},
                        }}},[_c('v-col',[_vm._v("Pedidos")]),_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.carregando,"label":"Buscar","outlined":"","dense":""},model:{value:(_vm.pedidos.busca),callback:function ($$v) {_vm.$set(_vm.pedidos, "busca", $$v)},expression:"pedidos.busca"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('Table',{attrs:{"pgLimitGet":5,"backendDirectory":"contrato/listar","busca":{
                            idcliente: parseInt(_vm.idcliente),
                            busca: _vm.contratos.busca || null
                        },"filter":true,"headers":{
                            idcontrato: {nome: 'ID', tipo: 'link', link: '/contrato/get/'},
                            filial: {nome: 'Filial', tipo: 'texto'},
                            dtcontrato: {nome: 'Data do pedido', tipo: 'date' },
                            status: {nome: 'Status', tipo: 'boolean', true: 'Contrato enviado', false: 'Contrato pendente'},
                        }}},[_c('v-col',[_vm._v("Contratos aguardando")]),_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.carregando,"label":"Buscar","outlined":"","dense":""},model:{value:(_vm.contratos.busca),callback:function ($$v) {_vm.$set(_vm.contratos, "busca", $$v)},expression:"contratos.busca"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }